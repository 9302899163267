import React, { useContext, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
 
import {MyContext} from '../contexts/MyContext';
 
export const PrivateRoute = ({ component: Component, ...rest}) => {
    const contexto = useContext(MyContext);
    const {logoutUser} = useContext(MyContext);

    useEffect(() => {
        const verificarAutenticacion = async () => {
            if (contexto.rootState.isAuth === '') {
                // Lógica cuando el estado de autenticación es desconocido
            } else if (contexto.rootState.isAuth === false) {
                // Lógica cuando el usuario no está autenticado
            } else {
                await corroborarAcceso();
            }
        };
    
        const corroborarAcceso = async () => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: contexto.rootState.theUser.idUsuario })
            };
            const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {
                if (data.allow === false) {
                    logoutUser();
                }
            }else{
                logoutUser();
            }
        };
    
        verificarAutenticacion();
    }, [contexto, logoutUser]);

    return (
            <Route
            {...rest}
            render={ () => {
                return (contexto.rootState.isAuth === '' ?
                            <></>
                        :
                            (contexto.rootState.isAuth === false) ?
                                <Redirect to='/login'/>
                            :
                                <Component/>
                )
            }}
            />
    )
}