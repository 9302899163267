import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './Login';
import Inicio from './Inicio';
import { LoginRoute } from './LoginRoute';
import { PrivateRoute } from './PrivateRoute';
import Ventas from './Ventas/Ventas';
import Merma from './Mermas/Merma';
import Mermas from './Mermas/Mermas';
import AbastecerAbarrotes from './Abastecer/AbastecerAbarrotes';
import Ingresos from './Ingresos/Ingresos';
import VentasExtra from './VentasExtra/VentasExtra';
import Egresos from './Egresos/Egresos';
import Pruebas from './Pruebas';
import RegistrarPan from './RegistrosPan/RegistrarPan';
import AbastecerOtros from './Abastecer/AbastecerOtros';
import RegistrosPan from './RegistrosPan/RegistrosPan';
import SurtirExpendio from './Expendio/SurtirExpendio';
import Surtidos from './Expendio/Surtidos';
import HacerPedido from './Pedidos/HacerPedido';
import Pedidos from './Pedidos/Pedidos';

function Routes () {

    return (
        <Switch>
          <LoginRoute path="/login" component={Login}/>
          <PrivateRoute exact path="/" component={Inicio}/>
          <PrivateRoute exact path="/ventas" component={Ventas}/>
          <PrivateRoute exact path="/merma" component={Merma}/>
          <PrivateRoute exact path="/mermas" component={Mermas}/>
          <PrivateRoute exact path="/expendios/surtir" component={SurtirExpendio}/>
          <PrivateRoute exact path="/expendios/surtidos" component={Surtidos}/>
          <PrivateRoute exact path="/pedidos/hacerpedido" component={HacerPedido}/>
          <PrivateRoute exact path="/pedidos/pedidos" component={Pedidos}/>
          <PrivateRoute exact path="/abastecer/abarrotes" component={AbastecerAbarrotes}/>
          <PrivateRoute exact path="/abastecer/otros" component={AbastecerOtros}/>
          <PrivateRoute exact path="/registrospan/registrar" component={RegistrarPan}/>
          <PrivateRoute exact path="/registrospan/registros" component={RegistrosPan}/>
          <PrivateRoute exact path="/ventasextra" component={VentasExtra}/>
          <PrivateRoute exact path="/extra/ingresos" component={Ingresos}/>
          <PrivateRoute exact path="/extra/egresos" component={Egresos}/>
          <PrivateRoute exact path="/pruebas" component={Pruebas}/>
          <Route exact path="*" component={() => <Redirect to={'/'}/>}/>
        </Switch>
      )
}

export default Routes;