import React from 'react';
import { MyContext } from '../../contexts/MyContext';
import Navbar from '../Navbar';
import '../../css/ventas.css';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import EditarVenta from './EditarVenta';

class Ventas extends React.Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = {
            ventas: [],
            fechaFiltro: '',
            ventaSeleccionada: [],
            showModalEliminar: false,
            showModalEditar: false,
            comentario: '',
            ventaEditar: []
        }
    }

    componentDidMount = async () => {
        document.title = 'Ventas';
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', sucursal: this.context.rootState.theUser.us_idSucursal })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/ventas.php', requestOptions);
        const data = await response.json();
        this.setState({
            ventas: data
        })
    }
    
    alertUser = (e) => {
        if (this.state.showModalEditar) {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("containerVentas")[0]) {
            document.getElementsByClassName("containerVentas")[0].style.height = (height - 110) + 'px';
            document.getElementsByClassName("containerDetalleVenta")[0].style.height = (height - 180) + 'px';
        }
    }

    selectVenta = async (e) => {
        let idVenta = e.target.id;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', idVenta: idVenta })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/ventas.php', requestOptions);
        const data = await response.json();
        console.log(data);
        this.setState({
            ventaSeleccionada: data[0]
        });
    }

    showModalEditar = () => {
        if (this.state.ventaSeleccionada.idVenta) {
            let fechaHoy = new Date().toLocaleDateString();
            let fechaVenta = new Date(this.state.ventaSeleccionada.fecha).toLocaleDateString();
            if (fechaHoy === fechaVenta) {
                if (window.confirm("Recurde que para editar una venta, debe solicitar el ticket anterior al cliente.")) {                    
                    this.setState({
                        showModalEditar: true
                    });
                }
            }else{
                toast.dismiss();
                toast.error('Solo puede editar ventas del día en curso.');
            }
        }else{
            toast.dismiss();
            toast.error('Para eso, seleccione una venta.');
        }
    }

    hideModalEditarUpdate = () => {
        this.setState({
            showModalEditar: false,
            ventaSeleccionada: []
        });
        this.componentDidMount();
    }

    hideModalEditar = () => {
        this.setState({
            showModalEditar: false
        })
    }

    hideModalEliminar = () => {
        this.setState({
            showModalEliminar: false
        });
    }

    showModalEliminar = () => {
        if (this.state.ventaSeleccionada.idVenta) {
            let fechaHoy = new Date().toLocaleDateString();
            let fechaVenta = new Date(this.state.ventaSeleccionada.fecha).toLocaleDateString();
            if (fechaHoy === fechaVenta) {
                this.setState({
                    showModalEliminar: true
                });
            }else{
                toast.dismiss();
                toast.error('Solo puede borrar ventas del día en curso.');
            }
        }else{
            toast.dismiss();
            toast.error('Para eso, seleccione una venta.');
        }
    }

    eliminarVenta = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                if (this.state.comentario !== '') {
                    let idVenta = this.state.ventaSeleccionada.idVenta;
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            operacion: 'delete', 
                            usuario: this.context.rootState.theUser.idUsuario,
                            idVenta: idVenta, 
                            comentario: this.state.comentario, 
                            productos: this.state.ventaSeleccionada.productos, 
                            sucursal: this.context.rootState.theUser.us_idSucursal 
                        })
                    };
                    const response = await fetch('https://pos.panaderialazcano.com/php/DB/ventas.php', requestOptions);
                    const data = await response.json();
                    toast.success(data.mensaje);
                    this.hideModalEliminar();
                    this.componentDidMount();
                    this.setState({
                        ventaSeleccionada: []
                    })
                }else{
                    toast.error('Debe escribir un motivo para la eliminación.');
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value 
        });
    }

    reimprimirTicket = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                let fechaHoy = new Date().toLocaleDateString();
                    let fechaVenta = new Date(this.state.ventaSeleccionada.fecha).toLocaleDateString();
                    if (fechaHoy === fechaVenta) {
                        if (window.confirm("Reimprimir ticket sólo debe ser usado en caso de error de impresión en el primer intento.")) {
                            const contenidoDiv = document.getElementById('ticket').innerHTML;
                            const ventanaImpresion = window.open('', '', 'height=700,width=1000');
                            ventanaImpresion.document.write('<html><head><title>Imprimir</title></head><body>');
                            ventanaImpresion.document.write(contenidoDiv);
                            ventanaImpresion.document.write('</body></html>');
                            ventanaImpresion.document.close();
                            ventanaImpresion.onafterprint = function() {
                                ventanaImpresion.close();
                            };
                            ventanaImpresion.print();
                        }
                    }else{
                        toast.dismiss();
                        toast.error('Solo puede editar ventas del día en curso.');
                    }
            }
        }else{
            this.context.logoutUser();
        }
        let fechaHoy = new Date().toLocaleDateString();
            let fechaVenta = new Date(this.state.ventaSeleccionada.fecha).toLocaleDateString();
            if (fechaHoy === fechaVenta) {
                if (window.confirm("Reimprimir ticket sólo debe ser usado en caso de error de impresión en el primer intento.")) {
                    const contenidoDiv = document.getElementById('ticket').innerHTML;
                    const ventanaImpresion = window.open('', '', 'height=700,width=1000');
                    ventanaImpresion.document.write('<html><head><title>Imprimir</title></head><body>');
                    ventanaImpresion.document.write(contenidoDiv);
                    ventanaImpresion.document.write('</body></html>');
                    ventanaImpresion.document.close();
                    ventanaImpresion.onafterprint = function() {
                        ventanaImpresion.close();
                    };
                    ventanaImpresion.print();
                }
            }else{
                toast.dismiss();
                toast.error('Solo puede editar ventas del día en curso.');
            }
    }
    
    render() { 
        return ( 
            <>
                <Navbar/>
                <div className="container-fluid ventas">
                    <div className="row">
                        <div className="col-4 px-4">
                            <div className="row pt-4">
                                <div className='containerVentas'>
                                    <div className="row py-2" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-4 text-center">
                                            Fecha
                                        </div>
                                        <div className="col-4 text-center">
                                            Hora
                                        </div>
                                        <div className="col-4 text-center">
                                            Total
                                        </div>
                                    </div>
                                    {
                                        this.state.ventas[0] ?
                                            this.state.ventas.map((e, index) =>
                                                <div className="row elementoVentas py-3" key={index} id={e.idVenta} onClick={this.selectVenta} style={{ cursor: 'pointer' }}>
                                                    <div className="col-4 text-center" id={e.idVenta}>
                                                        {new Date(e.fecha).toLocaleDateString()}
                                                    </div>
                                                    <div className="col-4 text-center" id={e.idVenta}>
                                                        {new Date(e.fecha).toLocaleTimeString()}
                                                    </div>
                                                    <div className="col-4 text-end" id={e.idVenta}>
                                                        ${this.numberWithCommas(e.total)}
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <div className="row mt-3">
                                                <div className="col-12 text-center">
                                                    No hay ventas registradas en esta fecha.
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-8 px-4 pt-4">
                            <div className="row">
                                <div className='containerDetalleVenta' style={{ overflowY: 'auto' }}>
                                    <div className="row py-3" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-12 text-center">
                                            <h5 className='m-0'>Detalles de la Venta</h5>
                                        </div>
                                    </div>
                                    {
                                        this.state.ventaSeleccionada.idVenta &&
                                            <>
                                                <div className="row" style={{ borderBottom: '5px solid #afc37f' }}>
                                                    <div className="col-3 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Fecha: {new Date(this.state.ventaSeleccionada.fecha).toLocaleDateString()}</h6>
                                                    </div>
                                                    <div className="col-3 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Hora: {new Date(this.state.ventaSeleccionada.fecha).toLocaleTimeString()}</h6>
                                                    </div>
                                                    <div className="col-3 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Pagado: {this.state.ventaSeleccionada.metodoPago.charAt(0).toUpperCase() + this.state.ventaSeleccionada.metodoPago.slice(1)}</h6>
                                                    </div>
                                                    <div className="col-3 text-center py-2">
                                                        <h6 className='m-0'>Total: ${this.numberWithCommas(this.state.ventaSeleccionada.total)}</h6>
                                                    </div>
                                                </div>
                                                {this.state.ventaSeleccionada.productos.map((e, index) => 
                                                    <div className="row py-3" key={index} style={{ borderBottom: '3px solid #afc37f3a' }}>
                                                        <div className="col-4 text-center">
                                                            {e.nombre}
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            {e.cantidad} Pzs.
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            ${this.numberWithCommas(e.precioUnitario)}
                                                        </div>
                                                    </div>
                                                )}
                                                <div id='ticket' style={{ height: 'fit-content', backgroundColor: 'white', width: '58mm', overflowX: 'hidden', position: 'absolute', padding: '0', left: '-1000px'}}>
                                                    <p style={{ fontSize: '16px', textAlign: 'center', margin: '0' }}>Panadería Lazcano</p>
                                                    <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>{this.context.rootState.theUser.nombreSucursal}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Folio: {this.state.ventaSeleccionada.idVenta}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Fecha: {this.state.ventaSeleccionada.fecha}</p>
                                                    <div style={{ display: 'grid', grid: '1fr / 1fr 1fr 1fr', margin: '0', fontSize: '12px' }}>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Cantidad</p>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Unitario</p>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Importe</p>
                                                    </div>
                                                    {
                                                        this.state.ventaSeleccionada.productos.map((e, index) =>
                                                            <div key={index} style={{ display: 'grid', grid: '1fr 1fr / 1fr 1fr 1fr' }}>
                                                                <p style={{ gridColumn: 'span 3', whiteSpace: 'nowrap', margin: '0', fontSize: '12px' }}>{e.nombre}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>{e.cantidad}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{Number(e.precioUnitario).toFixed(2)}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{(e.precioUnitario * e.cantidad).toFixed(2)}</p>
                                                            </div>
                                                        )
                                                    }
                                                    <p style={{ margin: '0', fontSize: '11px' }}>---------------------------------------------</p>
                                                    <p style={{ textAlign: 'end', margin: '0', fontSize: '12px' }}>Total: ${this.numberWithCommas(this.state.ventaSeleccionada.total)}</p>
                                                    <p style={{ textAlign: 'center', margin: '0', marginTop: '30px', fontSize: '12px' }}>¡Gracias por su compra!</p>
                                                    <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>Le atendió {this.context.rootState.theUser.nombre}</p>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="row pt-4 justify-content-center">
                                <button className='btn btn-secondary me-2 btn-lg w-auto' onClick={this.showModalEditar} hidden={!this.state.ventaSeleccionada.idVenta}>Editar Venta</button>
                                <button className='btn btn-success ms-2 btn-lg btnFinalizarVenta w-auto' onClick={this.showModalEliminar} hidden={!this.state.ventaSeleccionada.idVenta}>Eliminar Venta</button>
                                <button className='btn btn-success ms-2 btn-lg w-auto' onClick={this.reimprimirTicket} hidden={!this.state.ventaSeleccionada.idVenta}>Reimprimir Ticket</button>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.showModalEditar} onHide={this.hideModalEditar} fullscreen style={{ backgroundColor: '#e1e4c8' }}>
                        <Modal.Header className='d-flex justify-content-center' style={{ backgroundColor: '#947450', color: 'white' }} closeButton>
                            <h4>Editar Venta</h4>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: '#e1e4c8' }}>
                            <EditarVenta venta={this.state.ventaSeleccionada} cerrarModal={this.hideModalEditarUpdate}/>
                        </Modal.Body>
                    </Modal>
                    <Modal show={this.state.showModalEliminar} onHide={this.hideModalEliminar} centered>
                        <Modal.Header>
                            <h4>Eliminar Venta</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <label>Motivo de Eliminación:</label>
                            <textarea className='form-control' name="comentario" cols="30" rows="5" value={this.state.comentario} onChange={this.onChangeValue}></textarea>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-secondary' onClick={this.hideModalEliminar}>Cancelar</button>
                            <button className='btn btn-danger' onClick={this.eliminarVenta}>Eliminar</button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <Toaster containerClassName='text-center'/>
            </>
         );
    }
}
 
export default Ventas;