import React from 'react';
import { MyContext } from '../../contexts/MyContext';
import Navbar from '../Navbar';
import '../../css/ventas.css';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import EditarMerma from './EditarMerma';

class Mermas extends React.Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = {
            mermas: [],
            fechaFiltro: '',
            mermaSeleccionada: [],
            showModalEliminar: false,
            showModalEditar: false,
            comentario: '',
            mermaEditar: []
        }
    }

    componentDidMount = async () => {
        document.title = 'Mermas';
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', sucursal: this.context.rootState.theUser.us_idSucursal })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/mermas.php', requestOptions);
        const data = await response.json();
        this.setState({
            mermas: data
        })
    }

    alertUser = (e) => {
        if (this.state.showModalEditar) {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("containerVentas")[0]) {
            document.getElementsByClassName("containerVentas")[0].style.height = (height - 110) + 'px';
            document.getElementsByClassName("containerDetalleVenta")[0].style.height = (height - 180) + 'px';
        }
    }

    selectMerma = async (e) => {
        let idMerma = e.target.id;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', idMerma: idMerma })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/mermas.php', requestOptions);
        const data = await response.json();
        this.setState({
            mermaSeleccionada: data[0]
        });
    }

    showModalEditar = () => {
        if (this.state.mermaSeleccionada.idMerma) {
            let fechaHoy = new Date().toLocaleDateString();
            let fechaMerma = new Date(this.state.mermaSeleccionada.fecha).toLocaleDateString();
            if (fechaHoy === fechaMerma) {
                this.setState({
                    showModalEditar: true
                });
            }else{
                toast.dismiss();
                toast.error('Solo puede editar mermas del día en curso.');
            }
        }else{
            toast.dismiss();
            toast.error('Para eso, seleccione una merma.');
        }
    }

    hideModalEditarUpdate = () => {
        this.setState({
            showModalEditar: false,
            mermaSeleccionada: []
        });
        this.componentDidMount();
    }

    hideModalEditar = () => {
        this.setState({
            showModalEditar: false
        })
    }

    hideModalEliminar = () => {
        this.setState({
            showModalEliminar: false
        });
    }

    showModalEliminar = () => {
        if (this.state.mermaSeleccionada.idMerma) {
            let fechaHoy = new Date().toLocaleDateString();
            let fechaMerma = new Date(this.state.mermaSeleccionada.fecha).toLocaleDateString();
            if (fechaHoy === fechaMerma) {
                this.setState({
                    showModalEliminar: true
                });
            }else{
                toast.dismiss();
                toast.error('Solo puede borrar mermas del día en curso.');
            }
        }else{
            toast.dismiss();
            toast.error('Para eso, seleccione una merma.');
        }
    }

    eliminarMerma = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                if (this.state.comentario !== '') {
                    let idMerma = this.state.mermaSeleccionada.idMerma;
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            operacion: 'delete', 
                            usuario: this.context.rootState.theUser.idUsuario,
                            idMerma: idMerma, 
                            comentario: this.state.comentario, 
                            productos: this.state.mermaSeleccionada.productos, 
                            sucursal: this.context.rootState.theUser.us_idSucursal 
                        })
                    };
                    const response = await fetch('https://pos.panaderialazcano.com/php/DB/mermas.php', requestOptions);
                    const data = await response.json();
                    toast.success(data.mensaje);
                    this.hideModalEliminar();
                    this.componentDidMount();
                    this.setState({
                        mermaSeleccionada: []
                    })
                }else{
                    toast.error('Debe escribir un motivo para la eliminación.');
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value 
        });
    }
    
    render() { 
        return ( 
            <>
                <Navbar/>
                <div className="container-fluid ventas">
                    <div className="row">
                        <div className="col-4 px-4">
                            <div className="row pt-4">
                                <div className='containerVentas'>
                                    <div className="row py-2" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-4 text-center">
                                            Fecha
                                        </div>
                                        <div className="col-4 text-center">
                                            Hora
                                        </div>
                                        <div className="col-4 text-center">
                                            Total
                                        </div>
                                    </div>
                                    {
                                        this.state.mermas[0] ?
                                            this.state.mermas.map((e, index) =>
                                                <div className="row elementoVentas py-3" key={index} id={e.idMerma} onClick={this.selectMerma} style={{ cursor: 'pointer' }}>
                                                    <div className="col-4 text-center" id={e.idMerma}>
                                                        {new Date(e.fecha).toLocaleDateString()}
                                                    </div>
                                                    <div className="col-4 text-center" id={e.idMerma}>
                                                        {new Date(e.fecha).toLocaleTimeString()}
                                                    </div>
                                                    <div className="col-4 text-end" id={e.idMerma}>
                                                        ${this.numberWithCommas(e.total)}
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <div className="row mt-3">
                                                <div className="col-12 text-center">
                                                    No hay mermas registradas en esta fecha.
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-8 px-4 pt-4">
                            <div className="row">
                                <div className='containerDetalleVenta' style={{ overflowY: 'auto' }}>
                                    <div className="row py-3" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-12 text-center">
                                            <h5 className='m-0'>Detalles de la Merma</h5>
                                        </div>
                                    </div>
                                    {
                                        this.state.mermaSeleccionada.idMerma &&
                                            <>
                                                <div className="row" style={{ borderBottom: '5px solid #afc37f' }}>
                                                    <div className="col-4 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Fecha: {new Date(this.state.mermaSeleccionada.fecha).toLocaleDateString()}</h6>
                                                    </div>
                                                    <div className="col-4 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Hora: {new Date(this.state.mermaSeleccionada.fecha).toLocaleTimeString()}</h6>
                                                    </div>
                                                    <div className="col-4 text-center py-2">
                                                        <h6 className='m-0'>Total: ${this.numberWithCommas(this.state.mermaSeleccionada.total)}</h6>
                                                    </div>
                                                </div>
                                                {this.state.mermaSeleccionada.productos.map((e, index) => 
                                                    <div className="row py-3" key={index} style={{ borderBottom: '3px solid #afc37f3a' }}>
                                                        <div className="col-4 text-center">
                                                            {e.nombre}
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            {e.cantidad} Pzs.
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            ${this.numberWithCommas(e.precioUnitario)}
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="row pt-4 justify-content-center">
                                <button className='btn btn-secondary me-2 btn-lg w-auto' onClick={this.showModalEditar}>Editar Merma</button>
                                <button className='btn btn-success ms-2 btn-lg btnFinalizarVenta w-auto' onClick={this.showModalEliminar}>Eliminar Merma</button>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.showModalEditar} onHide={this.hideModalEditar} fullscreen style={{ backgroundColor: '#e1e4c8' }}>
                        <Modal.Header className='d-flex justify-content-center' style={{ backgroundColor: '#947450', color: 'white' }} closeButton>
                            <h4>Editar Merma</h4>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: '#e1e4c8' }}>
                            <EditarMerma merma={this.state.mermaSeleccionada} cerrarModal={this.hideModalEditarUpdate}/>
                        </Modal.Body>
                    </Modal>
                    <Modal show={this.state.showModalEliminar} onHide={this.hideModalEliminar} centered>
                        <Modal.Header>
                            <h4>Eliminar Merma</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <label>Motivo de Eliminación:</label>
                            <textarea className='form-control' name="comentario" cols="30" rows="5" value={this.state.comentario} onChange={this.onChangeValue}></textarea>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-secondary' onClick={this.hideModalEliminar}>Cancelar</button>
                            <button className='btn btn-danger' onClick={this.eliminarMerma}>Eliminar</button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <Toaster containerClassName='text-center'/>
            </>
         );
    }
}
 
export default Mermas;