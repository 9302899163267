import React from 'react';
import {MyContext} from '../../contexts/MyContext';
import '../../css/inicio.css';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { CardMedia } from '@mui/material';
import { CardActionArea } from '@mui/material';
import ClickNHold from 'react-click-n-hold'; 
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { MoonLoader } from 'react-spinners';

class EditarMerma extends React.Component {

    static contextType = MyContext;

    constructor(){
        super();
        this.state = {
            cargando: false,
            productoTipo: 'pan',
            productos: [],
            productosFiltrados: [],
            bolsas: [],
            listaProductos : [],
            productoModal: [],
            showModalCantidad: false,
            calValue: '',
            modalTipo: '',
            precioBolsaPapel: '',
            precioBolsaPlastico: '',
            buscarInput: '',
        }
        this._isMounted = false;
    }

    componentDidMount = async () => {
        document.title = 'Inicio';
        window.addEventListener('resize', this.getHeightWindow);
        this.getHeightWindow();
        this._isMounted = true;
        this._isMounted && this.select();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    select = async() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', sucursal: this.context.rootState.theUser.us_idSucursal })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/productos.php', requestOptions);
        const data = await response.json();
        if(this._isMounted){
            let listaProductos = [];
            this.props.merma.productos.forEach(element => {
                listaProductos.push({ idProducto: element.pm_idProducto, nombre: element.nombre, precio: element.precioUnitario, tipo: element.tipo, inventario: (Number(data.productos.find(e => e.idProducto === element.pm_idProducto).inventario) + Number(element.cantidad)), cantidad: element.cantidad });
                data.productos.find(e => e.idProducto === element.pm_idProducto).inventario = Number(data.productos.find(e => e.idProducto === element.pm_idProducto).inventario) + Number(element.cantidad);
            });
            let listaProductosNueva = listaProductos;
            let productosQuitar = [];
            listaProductosNueva.forEach(element => {
                if (Number(data.productos.find(e => e.idProducto === element.idProducto).inventario) > 0) {
                    if (Number(element.cantidad) > Number(data.productos.find(e => e.idProducto === element.idProducto).inventario)) {
                        element.cantidad = Number(data.productos.find(e => e.idProducto === element.idProducto).inventario);
                        toast('Cambio en las piezas de ' + element.nombre, { icon: '❕' });
                    }
                    element.inventario = Number(data.productos.find(e => e.idProducto === element.idProducto).inventario);
                }else{
                    productosQuitar.push(element.idProducto)
                }
            });
            if (productosQuitar.length > 0) {
                toast('Producto(s) borrados por falta de inventario.', { icon: '❕' });
                productosQuitar.forEach(element => {
                    listaProductosNueva = listaProductosNueva.filter(e => e.idProducto !== element);
                });
            }
            this.setState({
                productos: data.productos,
                productosFiltrados: data.productos,
                listaProductos: listaProductosNueva
            })
        }
    }

    selectWhere = async() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', sucursal: this.context.rootState.theUser.us_idSucursal })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/productos.php', requestOptions);
        const data = await response.json();
        if(this._isMounted){
            this.props.merma.productos.forEach(element => {
                data.productos.find(e => e.idProducto === element.pm_idProducto).inventario = Number(data.productos.find(e => e.idProducto === element.pm_idProducto).inventario) + Number(element.cantidad);
            });
            let listaProductosNueva = this.state.listaProductos;
            let productosQuitar = [];
            listaProductosNueva.forEach(element => {
                if (Number(data.productos.find(e => e.idProducto === element.idProducto).inventario) > 0) {
                    if (Number(element.cantidad) > Number(data.productos.find(e => e.idProducto === element.idProducto).inventario)) {
                        element.cantidad = Number(data.productos.find(e => e.idProducto === element.idProducto).inventario);
                        toast('Cambio en las piezas de ' + element.nombre, { icon: '❕' });
                    }
                    element.inventario = Number(data.productos.find(e => e.idProducto === element.idProducto).inventario);
                }else{
                    productosQuitar.push(element.idProducto)
                }
            });
            if (productosQuitar.length > 0) {
                toast('Producto(s) borrados por falta de inventario.', { icon: '❕' });
                productosQuitar.forEach(element => {
                    listaProductosNueva = listaProductosNueva.filter(e => e.idProducto !== element);
                });
            }
            this.setState({
                productos: data.productos,
                productosFiltrados: data.productos,
                listaProductos: listaProductosNueva
            })
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    endClickNHold(e, enough){     
        if (enough) {
            e.preventDefault();
        }
	} 
    
	clickNHold(idProducto){
        this.setState({
            showModalCantidad: true,
            modalTipo: 'agregar'
        })
        var pan = this.state.productos.find(pan => pan.idProducto === idProducto);
        this.setState({
            productoModal: pan
        })
	}

    hideModalCantidad = () => {
        this.setState({
            showModalCantidad: false,
            productoModal: [],
            calValue: '',
            modalTipo: '',
        })
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorPanes")[0]) {
            document.getElementsByClassName("contenedorPanes")[0].style.height = (height - 180) + 'px';
            document.getElementsByClassName("contenedorCuenta")[0].style.height = (height - 180) + 'px';
        }
    }

    clickTarjetaPan = (e) => {
        if (this.state.listaProductos.some(pan => pan.idProducto === e.idProducto)) {
            var cantidadPrevia = this.state.listaProductos.find(pan => pan.idProducto === e.idProducto).cantidad;
            var cantidadNueva = Number(cantidadPrevia) + 1;
            let inventario = Number(this.state.productos.find(pan => pan.idProducto === e.idProducto).inventario);
            if (cantidadNueva > inventario) {
                toast.dismiss();
                toast.error('Inventario Insuficiente');
            }else{
                var indexPan = this.state.listaProductos.findIndex((pan => pan.idProducto === e.idProducto));
                var listaProductosNueva = this.state.listaProductos;
                listaProductosNueva[indexPan].cantidad = cantidadNueva;
                this.setState({
                    listaProductos: listaProductosNueva
                })
            }
        }else{
            var pan = this.state.productos.find(pan => pan.idProducto === e.idProducto);
            pan.cantidad = 1;
            this.setState(prevState => ({
                listaProductos: [...prevState.listaProductos, pan]
                })
            )
        }
    }

    anadirPanLista = (idProducto) => {
        var cantidadPrevia = this.state.listaProductos.find(pan => pan.idProducto === idProducto).cantidad;
        var cantidadNueva = Number(cantidadPrevia) + 1;
        let inventario = Number(this.state.productos.find(pan => pan.idProducto === idProducto).inventario);
        if (cantidadNueva > inventario) {
            toast.dismiss();
            toast.error('Inventario Insuficiente');
        }else{
            var indexPan = this.state.listaProductos.findIndex((pan => pan.idProducto === idProducto));
            var listaProductosNueva = this.state.listaProductos;
            listaProductosNueva[indexPan].cantidad = cantidadNueva;
            this.setState({
                listaProductos: listaProductosNueva
            })
        }
    }

    quitarPanLista = (idProducto) => {
        if (Number(this.state.listaProductos.find(pan => pan.idProducto === idProducto).cantidad) === 1) {
            if (window.confirm("¿Eliminar producto de la lista?")) {
                let filteredArray = this.state.listaProductos.filter(Pan => Pan.idProducto !== idProducto)
                this.setState({
                    listaProductos: filteredArray
                })
            }
        }else{
            var cantidadPrevia = this.state.listaProductos.find(pan => pan.idProducto === idProducto).cantidad;
            var cantidadNueva = cantidadPrevia - 1;
            var indexPan = this.state.listaProductos.findIndex((pan => pan.idProducto === idProducto));
            var listaProductosNueva = this.state.listaProductos;
            listaProductosNueva[indexPan].cantidad = cantidadNueva;
            this.setState({
                listaProductos: listaProductosNueva
            })
        }
    }

    borrarPanLista = (idProducto) => {
        if (window.confirm("¿Eliminar producto de la lista?")) {
            let filteredArray = this.state.listaProductos.filter(Pan => Pan.idProducto !== idProducto)
            this.setState({
                listaProductos: filteredArray
            })
        }
    }
    
    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    clickNumero = (e) => {
        if (this.state.calValue === '' && e.target.id !== '0') {
            this.setState({
                calValue: this.state.calValue + e.target.id
            })
        }else{
            if (this.state.calValue !== '') {
                this.setState({
                    calValue: this.state.calValue + e.target.id
                })
            }
        }
    }

    clickBorrar = () => {
        if (this.state.calValue !== 0) {
            this.setState({
                calValue: (this.state.calValue).slice(0, -1)
            })
        }
    }

    aceptarCal = () => {
        if (Number(this.state.calValue) !== 0) {
            if (this.state.modalTipo === 'agregar') {
                if (this.state.listaProductos.some(pan => pan.idProducto === this.state.productoModal.idProducto)) {
                    var cantidadPrevia = this.state.listaProductos.find(pan => pan.idProducto === this.state.productoModal.idProducto).cantidad;
                    var cantidadNueva = Number(cantidadPrevia) + Number(this.state.calValue);
                    let inventario = Number(this.state.productos.find(pan => pan.idProducto === this.state.productoModal.idProducto).inventario);
                    if (cantidadNueva > inventario) {
                        toast.error('Inventario Insuficiente \n' + inventario + 'Pzas. Disponibles');
                    }else{
                        var indexPan = this.state.listaProductos.findIndex((pan => pan.idProducto === this.state.productoModal.idProducto));
                        var listaProductosNueva = this.state.listaProductos;
                        listaProductosNueva[indexPan].cantidad = cantidadNueva;
                        this.setState({
                            listaProductos: listaProductosNueva
                        })
                        this.hideModalCantidad();
                    }
                }else{
                    let inventario = Number(this.state.productos.find(pan => pan.idProducto === this.state.productoModal.idProducto).inventario);
                    if (this.state.calValue > inventario) {
                        toast.error('Inventario Insuficiente \n' + inventario + 'Pzas. Disponibles');
                    }else{
                        var pan = this.state.productos.find(pan => pan.idProducto === this.state.productoModal.idProducto);
                        pan.cantidad = Number(this.state.calValue);
                        this.setState(prevState => ({
                            listaProductos: [...prevState.listaProductos, pan]
                            })
                        )
                        this.hideModalCantidad();
                    }
                }
            }else{
                let inventario = Number(this.state.productos.find(pan => pan.idProducto === this.state.productoModal.idProducto).inventario);
                cantidadPrevia = this.state.listaProductos.find(pan => pan.idProducto === this.state.productoModal.idProducto).cantidad;
                cantidadNueva = this.state.calValue;
                if (cantidadNueva > inventario) {
                    toast.error('Inventario Insuficiente \n' + inventario + 'Pzas. Disponibles');
                }else{
                    indexPan = this.state.listaProductos.findIndex((pan => pan.idProducto === this.state.productoModal.idProducto));
                    listaProductosNueva = this.state.listaProductos;
                    listaProductosNueva[indexPan].cantidad = cantidadNueva;
                    this.setState({
                        listaProductos: listaProductosNueva
                    })
                    this.hideModalCantidad();
                }
            }
        }else{
            if (window.confirm("¿Eliminar producto de la lista?")) {
                let filteredArray = this.state.listaProductos.filter(Pan => Pan.idProducto !== this.state.productoModal.idProducto)
                this.setState({
                    listaProductos: filteredArray
                });                
                this.hideModalCantidad();
            }
        }
    }

    showModalModificar = (e, idProducto) => {
        var pan = this.state.productos.find(pan => pan.idProducto === idProducto);
        this.setState({
            productoModal: pan,
            showModalCantidad: true,
            modalTipo: 'modificar',
            calValue: e.target.id
        })
    }

    filterPan = async () => {
        await this.setState({
            productoTipo: 'pan'
        })
        this.buscar();
    }

    filterAbarrotes = async () => {
        await this.setState({
            productoTipo: 'abarrotes'
        })
        this.buscar();
    }

    onChangeValueBuscar = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value
        })
        this.buscar();
    }

    buscar = () => {
        if (this.state.buscarInput !== '') {
            let buscar = this.state.buscarInput.toLocaleUpperCase();
            var productosFiltrados = this.state.productos.filter(producto => producto.nombre.toLocaleUpperCase().includes(buscar));
            productosFiltrados = productosFiltrados.filter(producto => producto.tipo === this.state.productoTipo);
            this.setState({
                productosFiltrados: productosFiltrados
            })
        }else{
            this.setState({
                productosFiltrados: this.state.productos
            })
        }
    }

    getTotal = () => {
        let total = 0;
        let listaProductos = this.state.listaProductos
        listaProductos.forEach((producto) => {
            total = total += Number(producto.precio * producto.cantidad)
        })
        return total
    }

    finalizar = async() => {
        this.setState({
            cargando: false
        })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                if (this.state.listaProductos.length !== 0) {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            operacion: 'update',
                            usuario: this.context.rootState.theUser.idUsuario,
                            idMerma: this.props.merma.idMerma,
                            sucursal: this.context.rootState.theUser.us_idSucursal,
                            total: this.getTotal(),
                            productosBorrar: this.props.merma.productos,
                            productos: this.state.listaProductos
                        })
                    };
                    const response = await fetch('https://pos.panaderialazcano.com/php/DB/mermas.php', requestOptions);
                    const data = await response.json();
                    if (data.error === 0) {
                        this.setState({
                            cargando: false
                        })
                        toast.success('Merma Actualizada.');
                        this.props.cerrarModal();
                    }else{
                        if (data.codigoError === '22003') {
                            toast.error('Piezas insuficientes, recargue inventario.');
                            this.setState({
                                cargando: false
                            })
                        }else{
                            toast.error('Hubo un error al realizar la petición.');
                            this.setState({
                                cargando: false
                            })
                            console.log(data.mensaje);
                        }
                    }
                }else{
                    toast.error('La lista de productos no puede estar vacía.');
                    this.setState({
                        cargando: false
                    })
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    render() { 
            return ( 
            <>
                        <div className="row">
                            <div className="col-8 px-4 pt-0">
                                <div className="row">
                                    <div className="col-5">
                                        <input type="search" className='form-control inputBuscarProducto text-center' name='buscarInput' value={this.state.buscarInput} placeholder='Buscar' onChange={this.onChangeValueBuscar}/>
                                    </div>
                                    <div className="col-1">
                                        <button className='btn btn-success btn-lg btnInicio' onClick={this.selectWhere}><i className="bi bi-arrow-clockwise"></i></button>
                                    </div>
                                    <div className="col-2">
                                        <button className='btn btn-success btn-lg btnInicio' onClick={this.filterPan}>Pan</button>
                                    </div>
                                    <div className="col-2">
                                        <button className='btn btn-success btn-lg btnInicio' onClick={this.filterAbarrotes}>Abarrotes</button>
                                    </div>
                                    {this.state.productos.filter(producto => producto.tipo === 'bolsa').map((e, index) => 
                                        <div className="col-1" key={index}>
                                            <button className={'btn btn-success btn-lg btnInicio ' + e.nombre.replace(/\s/g, '')} onClick={() => this.clickTarjetaPan(e)} disabled={Number(e.inventario) === 0}></button>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div  className="contenedorPanes mt-4">
                                        <div className="row mt-4">
                                            {
                                                this.state.productosFiltrados.filter(producto => producto.tipo === this.state.productoTipo).map( e =>
                                                    <div key={e.idProducto} className="col-2 mb-4" hidden={Number(e.inventario) === 0}>
                                                        <ClickNHold time={0.5} onClickNHold={() => this.clickNHold(e.idProducto)} onEnd={this.endClickNHold}> 
                                                            <Card sx={{ height: '140px' }} className='tarjetaPan'>
                                                                <CardActionArea onClick={() => this.clickTarjetaPan(e)} onContextMenu={() => false}>
                                                                    <CardMedia
                                                                        component='img'
                                                                        className='imgTarjeta pt-1'
                                                                        image={'/img/productos/' + e.idProducto + '.png'} 
                                                                        onError={(e) => {e.target.src = '/img/productos/default.png'}}
                                                                        alt='Pan'
                                                                    />
                                                                    <CardContent className='text-center pt-1'>
                                                                        <div className='d-flex align-items-center justify-content-center' style={{ backgroundColor: '#FF6862', color: 'white', width: '35px', height: '35px', borderRadius: '100%', position: 'absolute', top: '5px', right: '5px' }}>
                                                                            <div>
                                                                                <p style={{ fontSize: '14px' }} className='m-0 p-0'>{e.inventario}</p>
                                                                                <p style={{ fontSize: '10px' }} className='m-0 p-0'> Pzas</p>
                                                                            </div>
                                                                        </div>
                                                                        <p style={{ fontSize: '15px' }} className='pb-0 mb-0'>$ {e.precio}</p>
                                                                        <p style={{ fontSize: '10px' }} className='pt-1 mt-0'>{e.nombre}</p>
                                                                    </CardContent>
                                                                </CardActionArea>
                                                            </Card>
                                                        </ClickNHold>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 px-4 pt-0">
                                <div className="row">
                                    <div className="contenedorCuenta p-0 d-flex">
                                        {
                                            this.state.listaProductos[0] 
                                            ?
                                                <ul className='list-group w-100'>
                                                    {   
                                                    this.state.listaProductos.map((e, index) =>
                                                        <li key={index} className='list-group-item itemListaPan'>
                                                            <div className="row pb-2">
                                                                <div className="col-1 p-0 d-flex align-items-center">
                                                                    <img src={'/img/productos/' + e.idProducto + '.png'} onError={(e) => {e.target.src = '/img/productos/default.png'}} alt="panLista" className='imglista'/>
                                                                </div>
                                                                <div className="col-5 p-0 d-flex align-items-center justify-content-center">
                                                                    <div className='mx-1 nombrePan'>{e.nombre}</div>
                                                                </div>
                                                                <div className="col-2 px-1">
                                                                    <button className='btn btn-outline-secondary btnItemLista btnQuitar w-100' onClick={() => this.quitarPanLista(e.idProducto)}/>
                                                                </div>
                                                                <div className="col-2 px-1">
                                                                    <button className='btn btn-outline-secondary btnItemLista btnAgregar w-100' onClick={() => this.anadirPanLista(e.idProducto)}/>
                                                                </div>
                                                                <div className="col-2 px-1">
                                                                    <button className='btn btn-outline-danger btnItemLista btnBorrar w-100' onClick={() => this.borrarPanLista(e.idProducto)}/>
                                                                </div>
                                                            </div>
                                                            <div className="row pt-1" style={{ borderTop: '1px solid #e1e4c8' }}>
                                                                <div className="col-6 text-center piezasImporte" style={{ borderRight: '1px solid #e1e4c8', cursor: 'pointer' }} id={e.cantidad} onClick={(element) => this.showModalModificar(element ,e.idProducto)}>
                                                                    {e.cantidad + ' Pza(s)'}
                                                                </div>
                                                                <div className="col-6 text-center piezasImporte">
                                                                    {'$ ' + this.numberWithCommas(e.cantidad * e.precio)}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            :
                                                <p className='mt-auto mb-auto ms-auto me-auto tablaProductosVacia'>Agregue productos a la lista</p>
                                        }
                                    </div>
                                </div>
                                <div className="row pt-4">
                                    <div className="col-6 d-flex align-items-center" style={{ border: '5px solid #afc37f', borderRadius: '5px', backgroundColor: 'white' }}>
                                        <div className="row w-100 m-0">
                                            <div className="col-5">
                                                <h5 className='m-0 p-0'>Total:</h5>
                                            </div>
                                            <div className="col-7 text-end">
                                                <h4 className='m-0 p-0'>$ {this.numberWithCommas(this.getTotal())}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <button type='button' className='btn btn-lg w-100 btnFinalizarVenta' onClick={this.finalizar}>Finalizar</button>
                                        <div id="ping"></div>
                                    </div>
                                </div>
                            </div>
                        <Modal show={this.state.showModalCantidad} centered size='md' onHide={this.hideModalCantidad}>
                            <Modal.Header className='d-flex justify-content-center'>
                                {
                                    this.state.modalTipo === 'agregar' ?
                                        <h5>Agregar Cantidad</h5>
                                    :
                                        <h5>Modificar Cantidad</h5>
                                }
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <h6>{this.state.productoModal.nombre}</h6>
                                    </div>
                                    <div className="col-3">
                                        <img src={'/img/productos/' + this.state.productoModal.idProducto + '.png'} onError={(e) => {e.target.src = '/img/productos/default.png'}} alt="pan"  width='100%'/>
                                    </div>
                                    <div className="col-9">
                                        <input type="number" style={{ height: '100%', width: '100%', borderRadius: '5px', textAlign: 'end', fontSize: '40px' }} name='calValue' value={this.state.calValue} onChange={this.onChangeValue}/>
                                    </div>
                                </div>
                                <hr />
                                <div className="row text-center">
                                    <div className="col-4 mt-2">
                                        <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='7' onClick={this.clickNumero}>7</button>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='8' onClick={this.clickNumero}>8</button>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='9' onClick={this.clickNumero}>9</button>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='4' onClick={this.clickNumero}>4</button>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='5' onClick={this.clickNumero}>5</button>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='6' onClick={this.clickNumero}>6</button>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='1' onClick={this.clickNumero}>1</button>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='2' onClick={this.clickNumero}>2</button>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='3' onClick={this.clickNumero}>3</button>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='0' onClick={this.clickNumero}>0</button>
                                    </div>
                                    <div className="col-8 mt-2">
                                        <button className='btn btn-outline-danger form-control' style={{ height: '50px', fontSize: '20px' }} onClick={this.clickBorrar}><i className="bi bi-backspace"></i></button>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className='btn btn-success btnInicio' onClick={this.aceptarCal}>Aceptar</button>
                            </Modal.Footer>
                        </Modal>
                        <Toaster containerClassName='text-center'/>
                        <Modal show={this.state.cargando} centered size='sm' className='modalCargando'>
                            <Modal.Body className='ms-auto me-auto'>
                                <MoonLoader color={'#ffffff'} loading={true} size={100} />
                            </Modal.Body>
                        </Modal>
                    </div>
                </>
                )
    }
}
 
export default EditarMerma;