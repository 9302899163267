import React, {useContext} from 'react';
import {MyContext} from '../contexts/MyContext';
import '../css/navbar.css';
import logo from '../img/logo.png';

function Navbar () {

    const {rootState,logoutUser} = useContext(MyContext);
    const {theUser} = rootState;

    return ( 
        <>
        <div>
        <nav className="navbar navbar-expand navbar-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="logo" className='logoNavbar'/>
                </a>
                <div className="collapse navbar-collapse justify-content-center">
                    <ul className={"navbar-nav mx-1 " + (window.location.pathname === '/' ? 'current' : '')}>
                        <li className="nav-item"><a className="nav-link" aria-current="page" href="/">Inicio</a></li>
                    </ul>
                    <ul className={"navbar-nav mx-1 " + (window.location.pathname === '/ventas' ? 'current' : '')}>
                        <li className="nav-item"><a className="nav-link" aria-current="page" href="/ventas">Ventas</a></li>
                    </ul>
                    <ul className={"navbar-nav mx-1 " + (window.location.pathname === '/merma' ? 'current' : '')}>
                        <li className="nav-item"><a className="nav-link" aria-current="page" href="/merma">Merma</a></li>
                    </ul>
                    <ul className={"navbar-nav mx-1 " + (window.location.pathname === '/mermas' ? 'current' : '')}>
                        <li className="nav-item"><a className="nav-link" aria-current="page" href="/mermas">Mermas</a></li>
                    </ul>
                    <div className="dropdown">
                        <button className={"mx-1 " + (window.location.pathname.includes('/expendios') ? 'current' : '')} 
                            type="button" 
                            id="dropdownMenuButton" 
                            data-mdb-toggle="dropdown" 
                            aria-expanded="false"
                            style={{ backgroundColor: 'transparent', border: 'none', color: 'rgba(255,255,255,.55)', height: '40px' }}
                        >
                            Expendios
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li><a className={"dropdown-item " + (window.location.pathname === '/expendios/surtir' ? 'current' : '')} href="/expendios/surtir">Surtir</a></li>
                            <li><a className={"dropdown-item " + (window.location.pathname === '/expendios/surtidos' ? 'current' : '')} href="/expendios/surtidos">Surtidos</a></li>
                        </ul>
                    </div>
                    <div className="dropdown">
                        <button className={"mx-1 " + (window.location.pathname.includes('/pedidos') ? 'current' : '')} 
                            type="button" 
                            id="dropdownMenuButton" 
                            data-mdb-toggle="dropdown" 
                            aria-expanded="false"
                            style={{ backgroundColor: 'transparent', border: 'none', color: 'rgba(255,255,255,.55)', height: '40px' }}
                        >
                            Pedidos
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li><a className={"dropdown-item " + (window.location.pathname === '/pedidos/hacerpedido' ? 'current' : '')} href="/pedidos/hacerpedido">Hacer Pedido</a></li>
                            <li><a className={"dropdown-item " + (window.location.pathname === '/pedidos/pedidos' ? 'current' : '')} href="/pedidos/pedidos">Pedidos</a></li>
                        </ul>
                    </div>
                    <div className="dropdown">
                        <button className={"mx-1 " + (window.location.pathname.includes('/registrospan') ? 'current' : '')} 
                            type="button" 
                            id="dropdownMenuButton" 
                            data-mdb-toggle="dropdown" 
                            aria-expanded="false"
                            style={{ backgroundColor: 'transparent', border: 'none', color: 'rgba(255,255,255,.55)', height: '40px' }}
                        >
                            Registros Pan
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li><a className={"dropdown-item " + (window.location.pathname === '/registrospan/registrar' ? 'current' : '')} href="/registrospan/registrar">Registrar</a></li>
                            <li><a className={"dropdown-item " + (window.location.pathname === '/registrospan/registros' ? 'current' : '')} href="/registrospan/registros">Registros</a></li>
                        </ul>
                    </div>
                    <div className="dropdown">
                        <button className={"mx-1 " + (window.location.pathname.includes('/abastecer') ? 'current' : '')} 
                            type="button" 
                            id="dropdownMenuButton" 
                            data-mdb-toggle="dropdown" 
                            aria-expanded="false"
                            style={{ backgroundColor: 'transparent', border: 'none', color: 'rgba(255,255,255,.55)', height: '40px' }}
                        >
                            Abastecer
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li><a className={"dropdown-item " + (window.location.pathname === '/abastecer/abarrotes' ? 'current' : '')} href="/abastecer/abarrotes">Abarrotes</a></li>
                            <li><a className={"dropdown-item " + (window.location.pathname === '/abastecer/otros' ? 'current' : '')} href="/abastecer/otros">Otros</a></li>
                        </ul>
                    </div>
                    <ul className={"navbar-nav mx-1 " + (window.location.pathname === '/ventasextra' ? 'current' : '')}>
                        <li className="nav-item"><a className="nav-link" aria-current="page" href="/ventasextra">Ventas Extra</a></li>
                    </ul>
                    <div className="dropdown">
                        <button className={"mx-1 " + (window.location.pathname.includes('/extra') ? 'current' : '')} 
                            type="button" 
                            id="dropdownMenuButton" 
                            data-mdb-toggle="dropdown" 
                            aria-expanded="false"
                            style={{ backgroundColor: 'transparent', border: 'none', color: 'rgba(255,255,255,.55)', height: '40px' }}
                        >
                            Extras
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li><a className={"dropdown-item " + (window.location.pathname === '/extra/ingresos' ? 'current' : '')} href="/extra/ingresos">Ingresos</a></li>
                            <li><a className={"dropdown-item " + (window.location.pathname === '/extra/egresos' ? 'current' : '')} href="/extra/egresos">Egresos</a></li>
                        </ul>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    {theUser && <span className="mx-3 navbar-text">Bienvenido, {theUser.nombre && theUser.nombre}</span>}
                    <button className="btn btnSalir btn-outline-light" onClick={logoutUser}>Salir</button>
                </div>
            </div>
        </nav>
        </div>
        </>
     );
}

export default Navbar ;