import React, { Component } from 'react';
import { MyContext } from '../../contexts/MyContext';
import Navbar from '../Navbar';
import ClickNHold from 'react-click-n-hold'; 
import { Card, CardActionArea, CardMedia, CardContent } from '@mui/material';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';
import { MoonLoader } from 'react-spinners';

class HacerPedido extends Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = {
            cargando: false,
            productos: [],
            productosFiltrados: [],
            listaProductos : [],
            productoModal: [],
            showModalCantidad: false,
            calValue: '',
            modalTipo: '',
            buscarInput: '',
            showModalFinalizar: false,
            nombre: '',
            estatus: 'Pendiente',
            anticipo: '',
            metodoPago: '',
            fechaEntregaProvista: '',
            fechaTicket: '',
            idPedidoTicket: ''
        }
    }

    componentDidMount = () => {
        document.title = 'Hacer Pedido';
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        this.selectPanes();
    }

    formatDate = (fecha) => {
        var fechaFormateada = fecha.split('/');
        fechaFormateada[0] = Number(fechaFormateada[0]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        fechaFormateada[1] = Number(fechaFormateada[1]).toLocaleString('es-MX', { minimumIntegerDigits: 2 });
        return fechaFormateada.reverse().join('-')
    }

    alertUser = (e) => {
        if (this.state.listaProductos.length > 0) {
            e.preventDefault();
            e.returnValue = '';   
        }
    }

    selectPanes = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                operacion: 'selectPanes',
                sucursal: this.context.rootState.theUser.us_idSucursal
            })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/productos.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (this.state.listaProductos.length > 0) {
                let listaProductosNueva = this.state.listaProductos;
                listaProductosNueva.forEach(element => {
                    element.inventario = Number(data.productos.find(e => e.idProducto === element.idProducto).inventario);
                });
                this.setState({
                    productos: data.productos,
                    productosFiltrados: data.productos,
                    listaProductos: listaProductosNueva
                })
            }else{
                this.setState({
                    productos: data.productos,
                    productosFiltrados: data.productos
                })
            }
        }else{
            toast.error('Error al obenter los panes.');
            console.log(data.mensaje);
        }
    }    

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorPanes")[0]) {
            document.getElementsByClassName("contenedorPanes")[0].style.height = (height - 180) + 'px';
            document.getElementsByClassName("contenedorCuenta")[0].style.height = (height - 180) + 'px';
        }
    }    

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    endClickNHold(e, enough){     
        if (enough) {
            e.preventDefault();
        }
	} 
    
	clickNHold(idProducto){
        this.setState({
            showModalCantidad: true,
            modalTipo: 'agregar'
        })
        var pan = this.state.productos.find(pan => pan.idProducto === idProducto);
        this.setState({
            productoModal: pan
        })
	}
    
    hideModalCantidad = () => {
        this.setState({
            showModalCantidad: false,
            productoModal: [],
            calValue: '',
            modalTipo: '',
        })
    }

    clickTarjetaPan = (e) => {
        if (this.state.listaProductos.some(pan => pan.idProducto === e.idProducto)) {
            var cantidadPrevia = this.state.listaProductos.find(pan => pan.idProducto === e.idProducto).cantidad;
            var cantidadNueva = cantidadPrevia + 1;
            var indexPan = this.state.listaProductos.findIndex((pan => pan.idProducto === e.idProducto));
            var listaProductosNueva = this.state.listaProductos;
            listaProductosNueva[indexPan].cantidad = cantidadNueva;
            this.setState({
                listaProductos: listaProductosNueva
            })
        }else{
            var pan = this.state.productos.find(pan => pan.idProducto === e.idProducto);
            pan.cantidad = 1;
            this.setState(prevState => ({
                listaProductos: [...prevState.listaProductos, pan]
                })
            )
        }
    }

    anadirPanLista = (idProducto) => {
        var cantidadPrevia = this.state.listaProductos.find(pan => pan.idProducto === idProducto).cantidad;
        var cantidadNueva = cantidadPrevia + 1;
        var indexPan = this.state.listaProductos.findIndex((pan => pan.idProducto === idProducto));
        var listaProductosNueva = this.state.listaProductos;
        listaProductosNueva[indexPan].cantidad = cantidadNueva;
        this.setState({
            listaProductos: listaProductosNueva
        })
    }

    quitarPanLista = (idProducto) => {
        if (this.state.listaProductos.find(pan => pan.idProducto === idProducto).cantidad === 1) {
            if (window.confirm("¿Eliminar producto de la lista?")) {
                let filteredArray = this.state.listaProductos.filter(Pan => Pan.idProducto !== idProducto)
                this.setState({
                    listaProductos: filteredArray
                })
            }
        }else{
            var cantidadPrevia = this.state.listaProductos.find(pan => pan.idProducto === idProducto).cantidad;
            var cantidadNueva = cantidadPrevia - 1;
            var indexPan = this.state.listaProductos.findIndex((pan => pan.idProducto === idProducto));
            var listaProductosNueva = this.state.listaProductos;
            listaProductosNueva[indexPan].cantidad = cantidadNueva;
            this.setState({
                listaProductos: listaProductosNueva
            })
        }
    }

    borrarPanLista = (idProducto) => {
        if (window.confirm("¿Eliminar producto de la lista?")) {
            let filteredArray = this.state.listaProductos.filter(Pan => Pan.idProducto !== idProducto)
            this.setState({
                listaProductos: filteredArray
            })
        }
    }
    
    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    clickNumero = (e) => {
        if (this.state.calValue === '' && e.target.id !== '0') {
            this.setState({
                calValue: this.state.calValue + e.target.id
            })
        }else{
            if (this.state.calValue !== '') {
                this.setState({
                    calValue: this.state.calValue + e.target.id
                })
            }
        }
    }

    clickBorrar = () => {
        if (this.state.calValue !== 0) {
            this.setState({
                calValue: (this.state.calValue).slice(0, -1)
            })
        }
    }

    aceptarCal = () => {
        if (Number(this.state.calValue) !== 0) {
            if (this.state.modalTipo === 'agregar') {
                if (this.state.listaProductos.some(pan => pan.idProducto === this.state.productoModal.idProducto)) {
                    var cantidadPrevia = this.state.listaProductos.find(pan => pan.idProducto === this.state.productoModal.idProducto).cantidad;
                    var cantidadNueva = Number(cantidadPrevia) + Number(this.state.calValue);
                    var indexPan = this.state.listaProductos.findIndex((pan => pan.idProducto === this.state.productoModal.idProducto));
                    var listaProductosNueva = this.state.listaProductos;
                    listaProductosNueva[indexPan].cantidad = cantidadNueva;
                    this.setState({
                        listaProductos: listaProductosNueva
                    })
                    this.hideModalCantidad();
                }else{
                    var pan = this.state.productos.find(pan => pan.idProducto === this.state.productoModal.idProducto);
                    pan.cantidad = this.state.calValue;
                    this.setState(prevState => ({
                        listaProductos: [...prevState.listaProductos, pan]
                        })
                    )
                    this.hideModalCantidad();
                }
            }else{
                cantidadPrevia = this.state.listaProductos.find(pan => pan.idProducto === this.state.productoModal.idProducto).cantidad;
                cantidadNueva = Number(this.state.calValue);
                indexPan = this.state.listaProductos.findIndex((pan => pan.idProducto === this.state.productoModal.idProducto));
                listaProductosNueva = this.state.listaProductos;
                listaProductosNueva[indexPan].cantidad = cantidadNueva;
                this.setState({
                    listaProductos: listaProductosNueva
                })
                this.hideModalCantidad();
            }
        }else{
            if (window.confirm("¿Eliminar producto de la lista?")) {
                let filteredArray = this.state.listaProductos.filter(Pan => Pan.idProducto !== this.state.productoModal.idProducto)
                this.setState({
                    listaProductos: filteredArray
                });                
                this.hideModalCantidad();
            }
        }
    }

    showModalModificar = (e, idProducto) => {
        var pan = this.state.productos.find(pan => pan.idProducto === idProducto);
        this.setState({
            productoModal: pan,
            showModalCantidad: true,
            modalTipo: 'modificar',
            calValue: e.target.id
        })
    }

    showModalFinalizar = () => {
        if (this.state.listaProductos.length !== 0) {
            this.setState({
                showModalFinalizar: true
            })
        }else{            
            toast.error('La lista de panes no puede estar vacía.');
        }
    }

    hideModalFinalizar = () => {
        this.setState({
            showModalFinalizar: false,
            nombre: '',
            estatus: 'Pendiente',
            anticipo: '',
            metodoPago: '',
            fechaEntregaProvista: ''
        })
    }

    onChangeValueBuscar = async (e) => {
        await this.setState({
            [e.target.name]: e.target.value
        })
        this.buscar();
    }

    buscar = () => {
        if (this.state.buscarInput !== '') {
            let buscar = this.state.buscarInput.toLocaleUpperCase();
            var productosFiltrados = this.state.productos.filter(producto => producto.nombre.toLocaleUpperCase().includes(buscar));
            this.setState({
                productosFiltrados: productosFiltrados
            })
        }else{
            this.setState({
                productosFiltrados: this.state.productos
            })
        }
    }

    onChangeEstatus = (value) => {
        if (this.state.estatus !== value) {
            if (value === 'Pendiente') {
                this.setState({
                    estatus: value,
                    anticipo: '',
                    metodoPago: ''
                })
            } else if (value === 'Anticipo') {
                this.setState({
                    estatus: value,
                    anticipo: '',
                    metodoPago: 'efectivo'
                })
            } else{
                this.setState({
                    estatus: value,
                    anticipo: this.getTotal(),
                    metodoPago: 'efectivo'
                })
            }            
        }
    }

    getTotalPiezas = () => {
        let total = 0;
        let listaProductos = this.state.listaProductos
        listaProductos.forEach((producto) => {
            total = total += Number(producto.cantidad)
        })
        return total
    }

    getTotal = () => {
        let total = 0;
        let listaProductos = this.state.listaProductos
        listaProductos.forEach((producto) => {
            total = total += Number(producto.precio * producto.cantidad)
        })
        return total
    }

    getFechaHoy = () => {
        return this.formatDate(new Date().toLocaleDateString());
    } 

    finalizar = async (e) => {
        e.preventDefault();
        this.setState({
            cargando: true
        })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                if (this.state.estatus === 'Anticipo') {
                    if ((this.state.anticipo > 0) && (this.state.anticipo < this.getTotal())) {
                        if (window.confirm('¿Está seguro de finalizar el pedido? Se registrará independientemente de si imprime el ticket o no.')) {
                            const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ 
                                    operacion: 'insert',
                                    usuario: this.context.rootState.theUser.idUsuario,
                                    sucursal: this.context.rootState.theUser.us_idSucursal,
                                    fechaEntregaProvista: this.state.fechaEntregaProvista,
                                    total: this.getTotal(),
                                    nombre: this.state.nombre,
                                    estatus: this.state.estatus,
                                    anticipo: this.state.anticipo,
                                    metodoPago: this.state.metodoPago,
                                    productos: this.state.listaProductos
                                })
                            };
                            const response = await fetch('https://pos.panaderialazcano.com/php/DB/pedidos.php', requestOptions);
                            const data = await response.json();
                            if (data.error === 0) {  
                                this.setState({
                                    fechaTicket: data.fechaTicket,
                                    idPedidoTicket: data.idPedidoTicket
                                })
                                const contenidoDiv = document.getElementById('ticket').innerHTML;
                                const ventanaImpresion = window.open('', '', 'height=700,width=1000');
                                ventanaImpresion.document.write('<html><head><title>Imprimir</title></head><body>');
                                ventanaImpresion.document.write(contenidoDiv);
                                ventanaImpresion.document.write('</body></html>');
                                ventanaImpresion.document.close();
                                ventanaImpresion.onafterprint = function() {
                                    ventanaImpresion.close();
                                };
                                ventanaImpresion.print();
                                toast.success('Pedido registrado.');
                                this.setState({
                                    listaProductos: [],
                                    fechaTicket: '',
                                    idPedidoTicket: '',
                                    cargando: false
                                })
                                this.hideModalFinalizar();
                                this.componentDidMount();
                            }else{
                                toast.error('Hubo un error al realizar la petición.');
                                this.setState({
                                    cargando: false
                                })
                                console.log(data.mensaje);
                            }
                        }
                    }else{
                        toast.error('El anticipo debe ser mayor a $0 y menor a $' + this.getTotal());
                        this.setState({
                            cargando: false
                        })
                    }
                }else{
                    if (window.confirm('¿Está seguro de finalizar el pedido? Se registrará independientemente de si imprime el ticket o no.')) {
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ 
                                operacion: 'insert',
                                usuario: this.context.rootState.theUser.idUsuario,
                                sucursal: this.context.rootState.theUser.us_idSucursal,
                                fechaEntregaProvista: this.state.fechaEntregaProvista,
                                total: this.getTotal(),
                                nombre: this.state.nombre,
                                estatus: this.state.estatus,
                                anticipo: this.state.anticipo,
                                metodoPago: this.state.metodoPago,
                                productos: this.state.listaProductos
                            })
                        };
                        const response = await fetch('https://pos.panaderialazcano.com/php/DB/pedidos.php', requestOptions);
                        const data = await response.json();
                        if (data.error === 0) {  
                            this.setState({
                                fechaTicket: data.fechaTicket,
                                idPedidoTicket: data.idPedidoTicket
                            })
                            const contenidoDiv = document.getElementById('ticket').innerHTML;
                            const ventanaImpresion = window.open('', '', 'height=700,width=1000');
                            ventanaImpresion.document.write('<html><head><title>Imprimir</title></head><body>');
                            ventanaImpresion.document.write(contenidoDiv);
                            ventanaImpresion.document.write('</body></html>');
                            ventanaImpresion.document.close();
                            ventanaImpresion.onafterprint = function() {
                                ventanaImpresion.close();
                            };
                            ventanaImpresion.print();
                            toast.success('Pedido registrado.');
                            this.setState({
                                listaProductos: [],
                                fechaTicket: '',
                                idPedidoTicket: '',
                                cargando: false
                            })
                            this.hideModalFinalizar();
                            this.componentDidMount();
                        }else{
                            toast.error('Hubo un error al realizar la petición.');
                            this.setState({
                                cargando: false
                            })
                            console.log(data.mensaje);
                        }
                    }
                }
            }
        }else{
            this.context.logoutUser();
        }
    }
  
    render() { 
        return ( 
            <>
            <Navbar/>
            <div className="container-fluid">
                <div className="row">
                    <div id='ticket' style={{ height: 'fit-content', backgroundColor: 'white', width: '58mm', overflowX: 'hidden', position: 'absolute', padding: '0', left: '-1000px'}}>
                        <p style={{ fontSize: '16px', textAlign: 'center', margin: '0' }}>Panadería Lazcano</p>
                        <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>{this.context.rootState.theUser.nombreSucursal}</p>
                        <p style={{ margin: '0', fontSize: '12px' }}>Folio: {this.state.idPedidoTicket}</p>
                        <p style={{ margin: '0', fontSize: '12px' }}>Fecha: {this.state.fechaTicket}</p>
                        <p style={{ margin: '0', fontSize: '12px' }}>Entrega Programada: {this.state.fechaEntregaProvista}</p>
                        <div style={{ display: 'grid', grid: '1fr / 1fr 1fr 1fr', margin: '0', fontSize: '12px' }}>
                            <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Cantidad</p>
                            <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Unitario</p>
                            <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Importe</p>
                        </div>
                        {
                            this.state.listaProductos.map((e, index) =>
                                <div key={index} style={{ display: 'grid', grid: '1fr 1fr / 1fr 1fr 1fr' }}>
                                    <p style={{ gridColumn: 'span 3', whiteSpace: 'nowrap', margin: '0', fontSize: '12px' }}>{e.nombre}</p>
                                    <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>{e.cantidad}</p>
                                    <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{Number(e.precio).toFixed(2)}</p>
                                    <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{(e.precio * e.cantidad).toFixed(2)}</p>
                                </div>
                            )
                        }
                        <p style={{ margin: '0', fontSize: '11px' }}>---------------------------------------------</p>
                        <p style={{ textAlign: 'end', margin: '0', fontSize: '12px' }}>Total: ${this.numberWithCommas(this.getTotal())}</p>
                        <p style={{ textAlign: 'start', margin: '0', marginTop: '10px', fontSize: '12px' }}>Pago: {this.state.estatus} {this.state.estatus === 'Anticipo' && '($' + this.numberWithCommas(this.state.anticipo) + ')' }</p>
                        <p style={{ textAlign: 'center', margin: '0', marginTop: '30px', fontSize: '12px' }}>¡Gracias por su pedido!</p>
                        <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>Le atendió {this.context.rootState.theUser.nombre}</p>
                    </div>
                    <div className="col-8 px-4 pt-4">
                        <div className="row">
                            <div className="col-5">
                                <input type="search" className='form-control inputBuscarProducto text-center' name='buscarInput' value={this.state.buscarInput} placeholder='Buscar' onChange={this.onChangeValueBuscar}/>
                            </div>
                            <div className="col-1">
                                <button className='btn btn-success btn-lg btnInicio' onClick={this.componentDidMount}><i className="bi bi-arrow-clockwise"></i></button>
                            </div>
                        </div>
                        <div className="row">
                            <div  className="contenedorPanes mt-4">
                                <div className="row mt-4">
                                    {
                                        this.state.productosFiltrados.map( e =>
                                            <div key={e.idProducto} className="col-2 mb-4">
                                                <ClickNHold time={0.5} onClickNHold={() => this.clickNHold(e.idProducto)} onEnd={this.endClickNHold}> 
                                                    <Card sx={{ height: '115px' }} className='tarjetaPan'>
                                                        <CardActionArea onClick={() => this.clickTarjetaPan(e)} onContextMenu={() => false}>
                                                            <CardMedia
                                                                component='img'
                                                                className='imgTarjeta pt-1'
                                                                image={'/img/productos/' + e.idProducto + '.png'} 
                                                                onError={(e) => {e.target.src = '/img/productos/default.png'}}
                                                                alt='Pan'
                                                            />
                                                            <CardContent className='text-center pt-1'>
                                                                <p style={{ fontSize: '10px' }} className='pt-1 mt-0'>{e.nombre}</p>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </ClickNHold>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 px-4 pt-4 ">
                        <div className="row">
                            <div className="contenedorCuenta p-0 d-flex">
                                {
                                    this.state.listaProductos[0] 
                                    ?
                                        <ul className='list-group w-100'>
                                            {   
                                            this.state.listaProductos.map((e, index) =>
                                                <li key={index} className='list-group-item itemListaPan'>
                                                    <div className="row pb-2">
                                                        <div className="col-1 p-0 d-flex align-items-center">
                                                            <img src={'/img/productos/' + e.idProducto + '.png'} onError={(e) => {e.target.src = '/img/productos/default.png'}} alt="panLista" className='imglista'/>
                                                        </div>
                                                        <div className="col-5 p-0 d-flex align-items-center justify-content-center">
                                                            <div className='mx-1 nombrePan'>{e.nombre}</div>
                                                        </div>
                                                        <div className="col-2 px-1">
                                                            <button className='btn btn-outline-secondary btnItemLista btnQuitar w-100' onClick={() => this.quitarPanLista(e.idProducto)}/>
                                                        </div>
                                                        <div className="col-2 px-1">
                                                            <button className='btn btn-outline-secondary btnItemLista btnAgregar w-100' onClick={() => this.anadirPanLista(e.idProducto)}/>
                                                        </div>
                                                        <div className="col-2 px-1">
                                                            <button className='btn btn-outline-danger btnItemLista btnBorrar w-100' onClick={() => this.borrarPanLista(e.idProducto)}/>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-1" style={{ borderTop: '1px solid #e1e4c8' }}>
                                                        <div className="col-6 text-center piezasImporte" style={{ borderRight: '1px solid #e1e4c8', cursor: 'pointer' }} id={e.cantidad} onClick={(element) => this.showModalModificar(element ,e.idProducto)}>
                                                            {e.cantidad + ' Pza(s)'}
                                                        </div>
                                                        <div className="col-6 text-center piezasImporte">
                                                            {'$ ' + this.numberWithCommas(e.cantidad * e.precio)}
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    :
                                        <p className='mt-auto mb-auto ms-auto me-auto tablaProductosVacia'>Agregue panes a la lista</p>
                                }
                            </div>
                        </div>
                        <div className="row pt-4">
                            <div className="col-6 d-flex align-items-center" style={{ border: '5px solid #afc37f', borderRadius: '5px', backgroundColor: 'white' }}>
                                <div className="row w-100 m-0">
                                    <div className="col-4 d-flex align-items-center">
                                        <h5 className='m-0 p-0'>Total:</h5>
                                    </div>
                                    <div className="col-8 d-flex align-items-center justify-content-end">
                                        <h4 className='m-0 p-0'>${this.numberWithCommas(this.getTotal())}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <button type='button' className='btn btn-lg w-100 btnFinalizarVenta' onClick={this.showModalFinalizar}>Continuar Pedido</button>
                                <div id="ping"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showModalCantidad} centered size='md' onHide={this.hideModalCantidad}>
                    <Modal.Header className='d-flex justify-content-center'>
                        {
                            this.state.modalTipo === 'agregar' ?
                                <h5>Agregar Cantidad</h5>
                            :
                                <h5>Modificar Cantidad</h5>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 text-center">
                                <h6>{this.state.productoModal.nombre}</h6>
                            </div>
                            <div className="col-3">
                                <img src={'/img/productos/' + this.state.productoModal.idProducto + '.png'} onError={(e) => {e.target.src = '/img/productos/default.png'}} alt="pan"  width='100%'/>
                            </div>
                            <div className="col-9">
                                <input type="number" style={{ height: '100%', width: '100%', borderRadius: '5px', textAlign: 'end', fontSize: '40px' }} name='calValue' value={this.state.calValue} onChange={this.onChangeValue}/>
                            </div>
                        </div>
                        <hr />
                        <div className="row text-center">
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='7' onClick={this.clickNumero}>7</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='8' onClick={this.clickNumero}>8</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='9' onClick={this.clickNumero}>9</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='4' onClick={this.clickNumero}>4</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='5' onClick={this.clickNumero}>5</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='6' onClick={this.clickNumero}>6</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='1' onClick={this.clickNumero}>1</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='2' onClick={this.clickNumero}>2</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='3' onClick={this.clickNumero}>3</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='0' onClick={this.clickNumero}>0</button>
                            </div>
                            <div className="col-8 mt-2">
                                <button className='btn btn-outline-danger form-control' style={{ height: '50px', fontSize: '20px' }} onClick={this.clickBorrar}><i className="bi bi-backspace"></i></button>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-success btnInicio' onClick={this.aceptarCal}>Aceptar</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showModalFinalizar} onHide={this.hideModalFinalizar} centered>
                    <form onSubmit={this.finalizar}>
                    <Modal.Header className='d-flex justify-content-center'>
                        <h5>Finalizar pedido</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="nombre" style={{ fontSize: '20px' }}>Nombre</label>
                                    <input type="text" name='nombre' id='nombre' className='form-control' value={this.state.nombre} onChange={this.onChangeValue} required style={{ fontSize: '20px', lineHeight: '46px' }}/>
                            </div>
                            <div className="col-12 mt-3">                                
                                <label style={{ fontSize: '20px' }}>Pago</label>
                            </div>
                            <div className="col-4 mt-1">
                                <button type='button' className={'btn btn-lg w-100 ' + (this.state.estatus === 'Pendiente' ? 'btn-success' : 'btn-outline-secondary')} onClick={() => this.onChangeEstatus('Pendiente')}>Pendiente</button>
                            </div>
                            <div className="col-4 mt-1">
                                <button type='button' className={'btn btn-lg w-100 ' + (this.state.estatus === 'Anticipo' ? 'btn-success' : 'btn-outline-secondary')} onClick={() => this.onChangeEstatus('Anticipo')}>Anticipo</button>
                            </div>
                            <div className="col-4 mt-1">
                                <button type='button' className={'btn btn-lg w-100 ' + (this.state.estatus === 'Pagado' ? 'btn-success' : 'btn-outline-secondary')} onClick={() => this.onChangeEstatus('Pagado')}>Pagado</button>
                            </div>
                            <div className="col-6 mt-3">
                                <label htmlFor="fechaEntregaProvista" style={{ fontSize: '20px' }}>Fecha de entrega</label>
                                    <input type="date" name="fechaEntregaProvista" id="fechaEntregaProvista" className='form-control' value={this.state.fechaEntregaProvista} onChange={this.onChangeValue} min={this.getFechaHoy()} required style={{ fontSize: '20px', lineHeight: '46px' }}/>
                            </div>
                            <div className="col-6 mt-3" hidden={this.state.estatus !== 'Anticipo'}>
                                <label htmlFor="anticipo" style={{ fontSize: '20px' }}>Anticipo</label>
                                    <div className="input-group">
                                        <span className='input-group-text' style={{ fontSize: '20px' }}>$</span>
                                        <NumericFormat value={this.state.anticipo} className="form-control text-end" defaultValue='' placeholder='0.0' onValueChange={(values) => this.setState({ anticipo: Number(values.value)})} required={this.state.estatus === 'Anticipo'} allowNegative={false} decimalScale={2} thousandSeparator=',' readOnly={this.state.estatus === 'Pagado'} style={{ fontSize: '30px' }}/>
                                    </div>
                            </div>
                            <div className="col-12 mt-3" hidden={this.state.estatus === 'Pendiente'}>                                
                                <label style={{ fontSize: '20px' }}>Método de pago</label>
                            </div>
                            <div className="col-6 mt-1" hidden={this.state.estatus === 'Pendiente'}>
                                <button type='button' className={'btn btn-lg w-100 ' + (this.state.metodoPago === 'efectivo' ? 'btn-success' : 'btn-outline-secondary')} onClick={() => this.setState({ metodoPago: 'efectivo' })}>Efectivo</button>
                            </div>
                            <div className="col-6 mt-1" hidden={this.state.estatus === 'Pendiente'}>
                                <button type='button' className={'btn btn-lg w-100 ' + (this.state.metodoPago === 'transferencia' ? 'btn-success' : 'btn-outline-secondary')} onClick={() => this.setState({ metodoPago: 'transferencia' })}>Transferencia</button>
                            </div>
                            <div className="col-12 mt-4">
                                <table className='table table-sm table-bordered bg-dark mb-0'>
                                    <thead>
                                        <tr className='text-center'>
                                            <th style={{ width: '33%', backgroundColor: '#F2F2F2', fontWeight: '500' }}>Total</th>
                                            <th style={{ width: '33%', fontWeight: '500' }}>Anticipo</th>
                                            <th style={{ backgroundColor: '#F2F2F2', fontWeight: '500' }}>Restante</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='text-end'>
                                            <td className='px-2' style={{ backgroundColor: '#F2F2F2' }}>$ {this.numberWithCommas(this.getTotal())}</td>
                                            <td className='px-2'>$ {this.numberWithCommas(this.state.anticipo)}</td>
                                            <td className='px-2' style={{ backgroundColor: '#F2F2F2' }}>$ {this.numberWithCommas(Number(this.getTotal()) - Number(this.state.anticipo))}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-secondary' type='button' onClick={this.hideModalFinalizar}>Cancelar</button>
                        <button className='btn btnFinalizarVenta' type='submit'>Hacer Pedido</button>
                    </Modal.Footer>
                    </form>
                </Modal>
                <Toaster containerClassName='text-center'/>
                <Modal show={this.state.cargando} centered size='sm' className='modalCargando'>
                    <Modal.Body className='ms-auto me-auto'>
                        <MoonLoader color={'#ffffff'} loading={true} size={100} />
                    </Modal.Body>
                </Modal>
            </div>
            </>
         );
    }
}
 
export default HacerPedido;