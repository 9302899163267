import React from 'react';
import { MyContext } from '../../contexts/MyContext';
import Navbar from '../Navbar';
import '../../css/ventas.css';
import { Toaster } from 'react-hot-toast';

class RegistrosPan extends React.Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = {
            registrosPan: [],
            fechaFiltro: '',
            registroPanSeleccionado: [],
            comentario: '',
        }
    }

    componentDidMount = async () => {
        document.title = 'Registros de Pan';
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', sucursal: this.context.rootState.theUser.us_idSucursal })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/registrospan.php', requestOptions);
        const data = await response.json();
        this.setState({
            registrosPan: data
        })
        this.filtrarHoy();
    }
    
    alertUser = (e) => {
        if (this.state.showModalEditar) {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("containerVentas")[0]) {
            document.getElementsByClassName("containerVentas")[0].style.height = (height - 110) + 'px';
            document.getElementsByClassName("containerDetalleVenta")[0].style.height = (height - 110) + 'px';
        }
    }

    filtrarHoy = () => {
        let fechaHoy = new Date();
        let dataFiltrada = this.state.registrosPan.filter((e) => new Date(e.fecha).toLocaleDateString() === fechaHoy.toLocaleDateString());
        this.setState({
            registrosPanFiltrado: dataFiltrada,
            fechaFiltro: ''
        })
    }

    filtrarTodas = () => {
        this.setState({
            registrosPanFiltrado: this.state.registrosPan,
            fechaFiltro: ''
        })
    }
    
    onChangeFechaFiltro = (e) => {
        let fechaFiltro = new Date(e.target.value + 'T00:00:00');
        let dataFiltrada = this.state.registrosPan.filter((e) => new Date(e.fecha).toLocaleDateString() === fechaFiltro.toLocaleDateString());
        this.setState({
            [e.target.name]: e.target.value,
            registrosPanFiltrado: dataFiltrada
        })
    }

    selectRegistro = async (e) => {
        let idRegistroPan = e.target.id;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', idRegistroPan: idRegistroPan })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/registrospan.php', requestOptions);
        const data = await response.json();
        console.log(data);
        this.setState({
            registroPanSeleccionado: data[0]
        });
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value 
        });
    }
    
    render() { 
        return ( 
            <>
                <Navbar/>
                <div className="container-fluid ventas">
                    <div className="row">
                        <div className="col-4 px-4">
                            <div className="row pt-4">
                                <div className='containerVentas'>
                                    <div className="row py-2" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-4 text-center">
                                            Fecha
                                        </div>
                                        <div className="col-4 text-center">
                                            Hora
                                        </div>
                                        <div className="col-4 text-center">
                                            Piezas
                                        </div>
                                    </div>
                                    {
                                        this.state.registrosPan[0] ?
                                            this.state.registrosPan.map((e, index) =>
                                                <div className="row elementoVentas py-3" key={index} id={e.idRegistroPan} onClick={this.selectRegistro} style={{ cursor: 'pointer' }}>
                                                    <div className="col-4 text-center" id={e.idRegistroPan}>
                                                        {new Date(e.fecha).toLocaleDateString()}
                                                    </div>
                                                    <div className="col-4 text-center" id={e.idRegistroPan}>
                                                        {new Date(e.fecha).toLocaleTimeString()}
                                                    </div>
                                                    <div className="col-4 text-end" id={e.idRegistroPan}>
                                                        {e.piezas} Pza(s)
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <div className="row mt-3">
                                                <div className="col-12 text-center">
                                                    No hay registros de pan en esta fecha.
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-8 px-4 pt-4">
                            <div className="row">
                                <div className='containerDetalleVenta' style={{ overflowY: 'auto' }}>
                                    <div className="row py-3" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-12 text-center">
                                            <h5 className='m-0'>Detalles del registro</h5>
                                        </div>
                                    </div>
                                    {
                                        this.state.registroPanSeleccionado.idRegistroPan &&
                                            <>
                                                <div className="row" style={{ borderBottom: '5px solid #afc37f' }}>
                                                    <div className="col-4 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Fecha: {new Date(this.state.registroPanSeleccionado.fecha).toLocaleDateString()}</h6>
                                                    </div>
                                                    <div className="col-4 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Hora: {new Date(this.state.registroPanSeleccionado.fecha).toLocaleTimeString()}</h6>
                                                    </div>
                                                    <div className="col-4 text-center py-2">
                                                        <h6 className='m-0'>Piezas: {this.state.registroPanSeleccionado.piezas}</h6>
                                                    </div>
                                                </div>
                                                {this.state.registroPanSeleccionado.productos.map((e, index) => 
                                                    <div className="row py-3" key={index} style={{ borderBottom: '3px solid #afc37f3a' }}>
                                                        <div className="col-6 text-center">
                                                            {e.nombre}
                                                        </div>
                                                        <div className="col-6 text-center">
                                                            {e.cantidad} Pzs.
                                                        </div>
                                                    </div>
                                                )}
                                                <div id='ticket' style={{ height: 'fit-content', backgroundColor: 'white', width: '58mm', overflowX: 'hidden', position: 'absolute', padding: '0', left: '-1000px'}}>
                                                    <p style={{ fontSize: '16px', textAlign: 'center', margin: '0' }}>Panadería Lazcano</p>
                                                    <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>{this.context.rootState.theUser.nombreSucursal}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Folio: {this.state.registroPanSeleccionado.idRegistroPan}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Fecha: {this.state.registroPanSeleccionado.fecha}</p>
                                                    <div style={{ display: 'grid', grid: '1fr / 1fr 1fr 1fr', margin: '0', fontSize: '12px' }}>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Cantidad</p>
                                                    </div>
                                                    {
                                                        this.state.registroPanSeleccionado.productos.map((e, index) =>
                                                            <div key={index} style={{ display: 'grid', grid: '1fr 1fr / 1fr 1fr 1fr' }}>
                                                                <p style={{ gridColumn: 'span 3', whiteSpace: 'nowrap', margin: '0', fontSize: '12px' }}>{e.nombre}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>{e.cantidad}</p>
                                                            </div>
                                                        )
                                                    }
                                                    <p style={{ margin: '0', fontSize: '11px' }}>---------------------------------------------</p>
                                                    <p style={{ textAlign: 'end', margin: '0', fontSize: '12px' }}>Total: ${this.numberWithCommas(this.state.registroPanSeleccionado.total)}</p>
                                                    <p style={{ textAlign: 'center', margin: '0', marginTop: '30px', fontSize: '12px' }}>¡Gracias por su compra!</p>
                                                    <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>Le atendió {this.context.rootState.theUser.nombre}</p>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toaster containerClassName='text-center'/>
            </>
         );
    }
}
 
export default RegistrosPan;