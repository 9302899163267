import React from 'react';
import { MyContext } from '../../contexts/MyContext';
import Navbar from '../Navbar';
import '../../css/ventas.css';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import EditarSurtidoPan from './EditarSurtidoPan';

class Surtidos extends React.Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = {
            surtidosPan: [],
            fechaFiltro: '',
            surtidoPanSeleccionada: [],
            showModalEliminar: false,
            showModalEditar: false,
            comentario: '',
            surtidoPanEditar: []
        }
    }

    componentDidMount = async () => {
        document.title = 'Surtidos';
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', sucursal: this.context.rootState.theUser.us_idSucursal })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/surtidospan.php', requestOptions);
        const data = await response.json();
        this.setState({
            surtidosPan: data,
        })
    }
    
    alertUser = (e) => {
        if (this.state.showModalEditar) {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("containerVentas")[0]) {
            document.getElementsByClassName("containerVentas")[0].style.height = (height - 110) + 'px';
            document.getElementsByClassName("containerDetalleVenta")[0].style.height = (height - 180) + 'px';
        }
    }

    selectSurtidoPan = async (e) => {
        let idSurtidoPan = e.target.id;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', idSurtidoPan: idSurtidoPan })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/surtidospan.php', requestOptions);
        const data = await response.json();
        console.log(data);
        this.setState({
            surtidoPanSeleccionada: data[0]
        });
    }

    showModalEditar = () => {
        if (this.state.surtidoPanSeleccionada.idSurtidoPan) {
            let fechaHoy = new Date().toLocaleDateString();
            let fechaSurtidoPan = new Date(this.state.surtidoPanSeleccionada.fecha).toLocaleDateString();
            if (fechaHoy === fechaSurtidoPan) {                  
                this.setState({
                    showModalEditar: true
                });
            }else{
                toast.dismiss();
                toast.error('Solo puede editar surtidos de pan del día en curso.');
            }
        }else{
            toast.dismiss();
            toast.error('Para eso, seleccione una surtido de pan.');
        }
    }

    hideModalEditarUpdate = () => {
        this.setState({
            showModalEditar: false,
            surtidoPanSeleccionada: []
        });
        this.componentDidMount();
    }

    hideModalEditar = () => {
        this.setState({
            showModalEditar: false
        })
    }

    hideModalEliminar = () => {
        this.setState({
            showModalEliminar: false
        });
    }

    showModalEliminar = () => {
        if (this.state.surtidoPanSeleccionada.idSurtidoPan) {
            let fechaHoy = new Date().toLocaleDateString();
            let fechaSurtidoPan = new Date(this.state.surtidoPanSeleccionada.fecha).toLocaleDateString();
            if (fechaHoy === fechaSurtidoPan) {
                this.setState({
                    showModalEliminar: true
                });
            }else{
                toast.dismiss();
                toast.error('Solo puede borrar surtidos de pan del día en curso.');
            }
        }else{
            toast.dismiss();
            toast.error('Para eso, seleccione una surtido de pan.');
        }
    }

    eliminarSurtidoPan = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                if (this.state.comentario !== '') {
                    let idSurtidoPan = this.state.surtidoPanSeleccionada.idSurtidoPan;
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            operacion: 'delete', 
                            usuario: this.context.rootState.theUser.idUsuario,
                            idSurtidoPan: idSurtidoPan, 
                            comentario: this.state.comentario, 
                            productos: this.state.surtidoPanSeleccionada.productos, 
                            sucursal: this.context.rootState.theUser.us_idSucursal, 
                            sucursalDestino: this.state.surtidoPanSeleccionada.sucursalDestino 
                        })
                    };
                    const response = await fetch('https://pos.panaderialazcano.com/php/DB/surtidospan.php', requestOptions);
                    const data = await response.json();
                    toast.success(data.mensaje);
                    this.hideModalEliminar();
                    this.componentDidMount();
                    this.setState({
                        surtidoPanSeleccionada: []
                    })
                }else{
                    toast.error('Debe escribir un motivo para la eliminación.');
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value 
        });
    }

    getTotalPiezas = () => {
        let total = 0;
        let listaProductos = this.state.surtidoPanSeleccionada.productos;
        listaProductos.forEach((producto) => {
            total += Number(producto.cantidad)
        })
        return total
    }
    
    render() { 
        return ( 
            <>
                <Navbar/>
                <div className="container-fluid ventas">
                    <div className="row">
                        <div className="col-5 px-4">
                            <div className="row pt-4">
                                <div className='containerVentas'>
                                    <div className="row py-2" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-4 text-center">
                                            Fecha
                                        </div>
                                        <div className="col-4 text-center">
                                            Hora
                                        </div>
                                        <div className="col-4 text-center">
                                            Destino
                                        </div>
                                    </div>
                                    {
                                        this.state.surtidosPan[0] ?
                                            this.state.surtidosPan.map((e, index) =>
                                                <div className="row elementoVentas py-3" key={index} id={e.idSurtidoPan} onClick={this.selectSurtidoPan} style={{ cursor: 'pointer' }}>
                                                    <div className="col-4 text-center" id={e.idSurtidoPan}>
                                                        {new Date(e.fecha).toLocaleDateString()}
                                                    </div>
                                                    <div className="col-4 text-center" id={e.idSurtidoPan}>
                                                        {new Date(e.fecha).toLocaleTimeString()}
                                                    </div>
                                                    <div className="col-4 text-center" id={e.idSurtidoPan}>
                                                        {e.nombreSucursalDestino}
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <div className="row mt-3">
                                                <div className="col-12 text-center">
                                                    No hay surtidos de pan registrados en esta fecha.
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-7 px-4 pt-4">
                            <div className="row">
                                <div className='containerDetalleVenta' style={{ overflowY: 'auto' }}>
                                    <div className="row py-3" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-12 text-center">
                                            <h5 className='m-0'>Detalles del surtido</h5>
                                        </div>
                                    </div>
                                    {
                                        this.state.surtidoPanSeleccionada.idSurtidoPan &&
                                            <>
                                                <div className="row" style={{ borderBottom: '5px solid #afc37f' }}>
                                                    <div className="col-3 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Fecha: {new Date(this.state.surtidoPanSeleccionada.fecha).toLocaleDateString()}</h6>
                                                    </div>
                                                    <div className="col-3 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Hora: {new Date(this.state.surtidoPanSeleccionada.fecha).toLocaleTimeString()}</h6>
                                                    </div>
                                                    <div className="col-3 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Destino: {this.state.surtidoPanSeleccionada.nombreSucursalDestino}</h6>
                                                    </div>
                                                    <div className="col-3 text-center py-2">
                                                        <h6 className='m-0'>Total: {this.getTotalPiezas()} Pzas.</h6>
                                                    </div>
                                                </div>
                                                {this.state.surtidoPanSeleccionada.productos.map((e, index) => 
                                                    <div className="row py-3" key={index} style={{ borderBottom: '3px solid #afc37f3a' }}>
                                                        <div className="col-9 text-center">
                                                            {e.nombre}
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            {e.cantidad} Pzs.
                                                        </div>
                                                    </div>
                                                )}
                                                <div id='ticket' style={{ height: 'fit-content', backgroundColor: 'white', width: '58mm', overflowX: 'hidden', position: 'absolute', padding: '0', left: '-1000px'}}>
                                                    <p style={{ fontSize: '16px', textAlign: 'center', margin: '0' }}>Panadería Lazcano</p>
                                                    <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>{this.context.rootState.theUser.nombreSucursal}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Folio: {this.state.surtidoPanSeleccionada.idSurtidoPan}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Fecha: {this.state.surtidoPanSeleccionada.fecha}</p>
                                                    <div style={{ display: 'grid', grid: '1fr / 1fr 1fr 1fr', margin: '0', fontSize: '12px' }}>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Cantidad</p>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Unitario</p>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Importe</p>
                                                    </div>
                                                    {
                                                        this.state.surtidoPanSeleccionada.productos.map((e, index) =>
                                                            <div key={index} style={{ display: 'grid', grid: '1fr 1fr / 1fr 1fr 1fr' }}>
                                                                <p style={{ gridColumn: 'span 3', whiteSpace: 'nowrap', margin: '0', fontSize: '12px' }}>{e.nombre}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>{e.cantidad}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{Number(e.precioUnitario).toFixed(2)}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{(e.precioUnitario * e.cantidad).toFixed(2)}</p>
                                                            </div>
                                                        )
                                                    }
                                                    <p style={{ margin: '0', fontSize: '11px' }}>---------------------------------------------</p>
                                                    <p style={{ textAlign: 'end', margin: '0', fontSize: '12px' }}>Total: ${this.numberWithCommas(this.state.surtidoPanSeleccionada.total)}</p>
                                                    <p style={{ textAlign: 'center', margin: '0', marginTop: '30px', fontSize: '12px' }}>¡Gracias por su compra!</p>
                                                    <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>Le atendió {this.context.rootState.theUser.nombre}</p>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="row pt-4 justify-content-center">
                                <button className='btn btn-secondary me-2 btn-lg w-auto' onClick={this.showModalEditar} hidden={!this.state.surtidoPanSeleccionada.idSurtidoPan}>Editar Surtido</button>
                                <button className='btn btn-success ms-2 btn-lg btnFinalizarVenta w-auto' onClick={this.showModalEliminar} hidden={!this.state.surtidoPanSeleccionada.idSurtidoPan}>Eliminar Surtido</button>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.showModalEditar} onHide={this.hideModalEditar} fullscreen style={{ backgroundColor: '#e1e4c8' }}>
                        <Modal.Header className='d-flex justify-content-center' style={{ backgroundColor: '#947450', color: 'white' }} closeButton>
                            <h4>Editar Surtido de Pan</h4>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: '#e1e4c8' }}>
                            <EditarSurtidoPan surtidoPan={this.state.surtidoPanSeleccionada} cerrarModal={this.hideModalEditarUpdate}/>
                        </Modal.Body>
                    </Modal>
                    <Modal show={this.state.showModalEliminar} onHide={this.hideModalEliminar} centered>
                        <Modal.Header>
                            <h4>Eliminar Surtido de Pan</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <label>Motivo de Eliminación:</label>
                            <textarea className='form-control' name="comentario" cols="30" rows="5" value={this.state.comentario} onChange={this.onChangeValue}></textarea>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-secondary' onClick={this.hideModalEliminar}>Cancelar</button>
                            <button className='btn btn-danger' onClick={this.eliminarSurtidoPan}>Eliminar</button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <Toaster containerClassName='text-center'/>
            </>
         );
    }
}
 
export default Surtidos;