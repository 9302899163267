import React, { Component } from 'react';
import { MyContext } from '../../contexts/MyContext';
import Navbar from '../Navbar';
import toast, { Toaster } from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';
import { Modal } from 'react-bootstrap';
import { MoonLoader } from 'react-spinners';

class Ingresos extends Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = {
            cargando: false,
            ingresos: [],
            fechaFiltro: '',
            ingresoSeleccionado: [],
            showModalEliminar: false,
            concepto: '',
            monto: ''
        }
    }

    componentDidMount = async () => {
        document.title = 'Ingresos';
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', sucursal: this.context.rootState.theUser.us_idSucursal })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/ingresos.php', requestOptions);
        const data = await response.json();
        this.setState({
            ingresos: data
        })
        this.filtrarHoy();
    }
    
    alertUser = (e) => {
        if (this.state.concepto !== '' || Number(this.state.monto)) {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("containerVentas")[0]) {
            document.getElementsByClassName("containerVentas")[0].style.height = (height - 110) + 'px';
            document.getElementsByClassName("containerDetalleVenta")[0].style.maxHeight = (height - 110) + 'px';
        }
    }

    filtrarHoy = () => {
        let fechaHoy = new Date();
        let dataFiltrada = this.state.ingresos.filter((e) => new Date(e.fecha).toLocaleDateString() === fechaHoy.toLocaleDateString());
        this.setState({
            ingresosFiltrado: dataFiltrada,
            fechaFiltro: ''
        })
    }

    filtrarTodas = () => {
        this.setState({
            ingresosFiltrado: this.state.ingresos,
            fechaFiltro: ''
        })
    }
    
    onChangeFechaFiltro = (e) => {
        let fechaFiltro = new Date(e.target.value + 'T00:00:00');
        let dataFiltrada = this.state.ingresos.filter((e) => new Date(e.fecha).toLocaleDateString() === fechaFiltro.toLocaleDateString());
        this.setState({
            [e.target.name]: e.target.value,
            ingresosFiltrado: dataFiltrada
        })
    }

    eliminarIngreso = async (id, fecha, concepto, total) => { 
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                let fechaHoy = new Date().toLocaleDateString();
                let fechaIngreso = new Date(fecha).toLocaleDateString();
                if (fechaHoy === fechaIngreso) {
                    if (window.confirm('¿Borrar ingreso?')) {
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ 
                                operacion: 'delete', 
                                usuario: this.context.rootState.theUser.idUsuario,
                                sucursal: this.context.rootState.theUser.us_idSucursal,
                                idIngreso: id,
                                concepto: concepto,
                                total: total
                            })
                        };
                        const response = await fetch('https://pos.panaderialazcano.com/php/DB/ingresos.php', requestOptions);
                        const data = await response.json();
                        if (data.error === 0) {
                            toast.success(data.mensaje);
                            this.componentDidMount();
                        }else{
                            toast.error('Error al borrar ingreso.');
                            console.log(data);
                        }
                    }
                }else{
                    toast.dismiss();
                    toast.error('Solo puede borrar ingresos del día en curso.');
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value 
        });
    }

    agregarIngreso = async () => {
        this.setState({
            cargando: true
        })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                if (this.state.concepto !== '' && Number(this.state.monto) !== 0) {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            operacion: 'insert', 
                            usuario: this.context.rootState.theUser.idUsuario,
                            sucursal: this.context.rootState.theUser.us_idSucursal, 
                            concepto: this.state.concepto, 
                            total: this.state.monto 
                        })
                    };
                    const response = await fetch('https://pos.panaderialazcano.com/php/DB/ingresos.php', requestOptions);
                    const data = await response.json();
                    if (data.error === 0) {
                        toast.success(data.mensaje);
                        this.setState({
                            concepto: '',
                            monto: '',
                            cargando: false
                        });
                        this.componentDidMount();
                    }else{
                        toast.error('Error al registrar ingreso.');
                        this.setState({
                            cargando: false
                        })
                        console.log(data);
                    }
                }else{
                    toast.error('Para eso, llene los campos solicitados.');
                    this.setState({
                        cargando: false
                    })
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    render() { 
        return ( 
            <>
                <Navbar/>
                <div className="container-fluid ventas">
                    <div className="row">
                        <div className="col-7 px-4">
                            <div className="row pt-4">
                                <div className='containerVentas'>
                                    <div className="row py-2" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col5 text-center">
                                            Fecha
                                        </div>
                                        <div className="col5 text-center">
                                            Hora
                                        </div>
                                        <div className="col5 text-center">
                                            Concepto
                                        </div>
                                        <div className="col5 text-center">
                                            Total
                                        </div>
                                        <div className="col5 text-center">
                                            -
                                        </div>
                                    </div>
                                    {
                                        this.state.ingresos[0] ?
                                            this.state.ingresos.map((e, index) =>
                                                <div className="row elementoVentas py-3" key={index}>
                                                    <div className="col5 d-flex justify-content-center align-items-center">
                                                        <p className='m-0'>{new Date(e.fecha).toLocaleDateString()}</p>
                                                    </div>
                                                    <div className="col5 d-flex justify-content-center align-items-center">
                                                        {new Date(e.fecha).toLocaleTimeString()}
                                                    </div>
                                                    <div className="col5 d-flex align-items-center">
                                                        {e.concepto}
                                                    </div>
                                                    <div className="col5 d-flex justify-content-end align-items-center">
                                                        ${this.numberWithCommas(e.total)}
                                                    </div>
                                                    <div className="col5 d-flex justify-content-center align-items-center">
                                                        <button className='btn btn-success w-100 btnFinalizarVenta' onClick={() => this.eliminarIngreso(e.idIngreso, e.fecha, e.concepto, this.numberWithCommas(e.total))}><i className="bi bi-trash2-fill"></i></button>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <div className="row mt-3">
                                                <div className="col-12 text-center">
                                                    No hay ingresos registrados en esta fecha.
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-5 px-4 pt-4">
                            <div className="row">
                                <div className='containerDetalleVenta' style={{ height: 'fit-content' }}>
                                    <div className="row py-3" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-12 text-center">
                                            <h5 className='m-0'>Registrar Ingreso</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 pt-2">
                                            <label htmlFor="concepto" className='ms-1'>Concepto</label>
                                                <input type="text" id='concepto' className='form-control' name='concepto' value={this.state.concepto} onChange={this.onChangeValue}/>
                                            <label htmlFor="monto" className='ms-1 mt-3'>Monto</label>
                                                <NumericFormat value={this.state.monto} className="form-control text-end" defaultValue='' placeholder='$0.0' onValueChange={(values) => this.setState({ monto: Number(values.value)})} allowNegative={false} decimalScale={2} thousandSeparator=',' prefix={'$'}/>
                                            <button className='my-3 mx-auto d-flex btn btnFinalizarVenta px-4' onClick={this.agregarIngreso}>Agregar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toaster containerClassName='text-center'/>
                <Modal show={this.state.cargando} centered size='sm' className='modalCargando'>
                    <Modal.Body className='ms-auto me-auto'>
                        <MoonLoader color={'#ffffff'} loading={true} size={100} />
                    </Modal.Body>
                </Modal> 
            </>
         );
    }
}
 
export default Ingresos;