import React from 'react';
import MyContextProvider from './contexts/MyContext';
import { BrowserRouter as Router} from 'react-router-dom';
import Routes from './components/Routes';

function App() {
  return (
    <div>
    <MyContextProvider> 
        <Router>
          <Routes/>
        </Router>
    </MyContextProvider>
    </div>
  );
}

export default App;