import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
 
import {MyContext} from '../contexts/MyContext';
 
export const LoginRoute = ({ component: Component, ...rest}) => {
    const contexto = useContext(MyContext);

    return (
            <Route
            {...rest}
            render={ () => {
                return (contexto.rootState.isAuth === '' ?
                            <></>
                        :
                            (contexto.rootState.isAuth === false) ?
                                <Component/>
                            :
                                <Redirect to='/'/>
                )
            }}
            />
    )
}