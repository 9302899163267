import React, { Component } from 'react';
import { MyContext } from '../../contexts/MyContext';
import Navbar from '../Navbar';
import { Card, CardMedia, CardActionArea, CardContent } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import { Modal } from 'react-bootstrap';

class AbastecerAbarrotes extends Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = {
            abarrotes: [],
            idAbarrote: '',
            nombreAbarrote: '',
            calValue: '',
            showModal: false
        }
    }

    componentDidMount = async () => {
        document.title = 'Abastecer Abarrotes';
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                operacion: 'selectAbarrotes',
                sucursal: this.context.rootState.theUser.us_idSucursal
            })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/productos.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                abarrotes: data.productos
            })
        }else{
            toast.error('Error al obtener los abarrotes.');
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("contenedorPanes")[0]) {
            document.getElementsByClassName("contenedorPanes")[0].style.height = (height - 100) + 'px';
        }
    }
    
    alertUser = (e) => {
        if (this.state.showModal) {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    clickTarjetaAbarrote = (e) => {
        this.setState({
            showModal: true,
            idAbarrote: e.idProducto,
            nombreAbarrote: e.nombre
        })
    }

    hideModal = () => {
        this.setState({
            showModal: false,
            idAbarrote: '',
            nombreAbarrote: '',
            calValue: ''
        })
    }
    
    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    clickNumero = (e) => {
        if (this.state.calValue === '' && e.target.id !== '0') {
            this.setState({
                calValue: this.state.calValue + e.target.id
            })
        }else{
            if (this.state.calValue !== '') {
                this.setState({
                    calValue: this.state.calValue + e.target.id
                })
            }
        }
    }

    clickBorrar = () => {
        if (this.state.calValue !== 0) {
            this.setState({
                calValue: (this.state.calValue).slice(0, -1)
            })
        }
    }

    aceptarCal = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                if (Number(this.state.calValue) !== 0) {
                    if (window.confirm('¿Agregar ' + this.state.calValue + ' piezas de ' + this.state.nombreAbarrote + '?')) {
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                operacion: 'agregarPiezasAbarrotes',
                                usuario: this.context.rootState.theUser.idUsuario,
                                sucursal: this.context.rootState.theUser.us_idSucursal,
                                idProducto: this.state.idAbarrote,
                                piezas: this.state.calValue
                            })
                        };
                        const response = await fetch('https://pos.panaderialazcano.com/php/DB/productos.php', requestOptions);
                        const data = await response.json();
                        if (data.error === 0) {
                            toast.success('Inventario actualizado.');
                            this.hideModal();
                            this.componentDidMount();
                        }else{
                            toast.error('Error al actualizar inventario.');
                        }
                    }
                }else{
                    this.hideModal();
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    render() { 
        return ( 
            <>
                <Navbar/>
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="contenedorPanes p-3">
                                <div className="row">
                                    {
                                        this.state.abarrotes.map( e =>
                                            <div key={e.idProducto} className="col-2 mb-4">
                                                <Card sx={{ height: '110px' }} className='tarjetaPan'>
                                                    <CardActionArea onClick={() => this.clickTarjetaAbarrote(e)} onContextMenu={() => false}>
                                                        <CardMedia
                                                            component='img'
                                                            className='imgTarjeta pt-1'
                                                            image={'/img/productos/' + e.idProducto + '.png'}
                                                            onError={(e) => {e.target.src = '/img/productos/default.png'}}
                                                            alt='Pan'
                                                        />
                                                        <CardContent className='text-center pt-1'>
                                                            <div className='d-flex align-items-center justify-content-center' style={{ backgroundColor: '#FF6862', color: 'white', width: '35px', height: '35px', borderRadius: '100%', position: 'absolute', top: '5px', right: '5px' }}>
                                                                <div>
                                                                    <p style={{ fontSize: '14px' }} className='m-0 p-0'>{e.inventario}</p>
                                                                    <p style={{ fontSize: '10px' }} className='m-0 p-0'> Pzas</p>
                                                                </div>
                                                            </div>
                                                            <p style={{ fontSize: '14px' }} className='pt-1 mt-0'>{e.nombre}</p>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showModal} centered size='md' onHide={this.hideModal}>
                    <Modal.Header className='d-flex justify-content-center'>
                        <h5 className='m-0'>Agregar Cantidad</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 text-center">
                                <h6>{this.state.nombreAbarrote}</h6>
                            </div>
                            <div className="col-3">
                                <img src={'/img/productos/' + this.state.idAbarrote + '.png'} onError={(e) => {e.target.src = '/img/productos/default.png'}} alt="pan"  width='100%'/>
                            </div>
                            <div className="col-9">
                                <input type="number" style={{ height: '100%', width: '100%', borderRadius: '5px', textAlign: 'end', fontSize: '40px' }} name='calValue' value={this.state.calValue} onChange={this.onChangeValue}/>
                            </div>
                        </div>
                        <hr />
                        <div className="row text-center">
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='7' onClick={this.clickNumero}>7</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='8' onClick={this.clickNumero}>8</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='9' onClick={this.clickNumero}>9</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='4' onClick={this.clickNumero}>4</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='5' onClick={this.clickNumero}>5</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='6' onClick={this.clickNumero}>6</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='1' onClick={this.clickNumero}>1</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='2' onClick={this.clickNumero}>2</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='3' onClick={this.clickNumero}>3</button>
                            </div>
                            <div className="col-4 mt-2">
                                <button className='btn btn-outline-dark form-control' style={{ height: '50px', fontSize: '25px' }} id='0' onClick={this.clickNumero}>0</button>
                            </div>
                            <div className="col-8 mt-2">
                                <button className='btn btn-outline-danger form-control' style={{ height: '50px', fontSize: '20px' }} onClick={this.clickBorrar}><i className="bi bi-backspace"></i></button>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-success btnInicio' onClick={this.aceptarCal}>Aceptar</button>
                    </Modal.Footer>
                </Modal>
                <Toaster/>
            </>
         );
    }
}
 
export default AbastecerAbarrotes;