import React, { Component } from 'react';
import { MyContext } from '../../contexts/MyContext';
import Navbar from '../Navbar';
import toast, { Toaster } from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';
import { Modal } from 'react-bootstrap';
import { MoonLoader } from 'react-spinners';

class VentasExtra extends Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = {
            cargando: false,
            ventasExtra: [],
            fechaFiltro: '',
            ventaExtraSeleccionado: [],
            showModalEliminar: false,
            concepto: '',
            monto: '',
            fechaTicket: '',
            idVentaTicket: '',
            metodoPago: 'efectivo'
        }
    }

    componentDidMount = async () => {
        document.title = 'Ventas Extra';
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', sucursal: this.context.rootState.theUser.us_idSucursal })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/ventasextra.php', requestOptions);
        const data = await response.json();
        this.setState({
            ventasExtra: data
        })
    }
    
    alertUser = (e) => {
        if (this.state.concepto !== '' || Number(this.state.monto)) {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("containerVentas")[0]) {
            document.getElementsByClassName("containerVentas")[0].style.height = (height - 110) + 'px';
            document.getElementsByClassName("containerDetalleVenta")[0].style.maxHeight = (height - 110) + 'px';
        }
    }

    eliminarVentaExtra = async (id, fecha, concepto, total) => { 
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                let fechaHoy = new Date().toLocaleDateString();
                let fechaVentaExtra = new Date(fecha).toLocaleDateString();
                if (fechaHoy === fechaVentaExtra) {
                    if (window.confirm('¿Borrar venta extra?')) {
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ 
                                operacion: 'delete', 
                                usuario: this.context.rootState.theUser.idUsuario,
                                sucursal: this.context.rootState.theUser.us_idSucursal, 
                                idVentaExtra: id,
                                concepto: concepto,
                                total: total
                            })
                        };
                        const response = await fetch('https://pos.panaderialazcano.com/php/DB/ventasextra.php', requestOptions);
                        const data = await response.json();
                        if (data.error === 0) {
                            toast.success(data.mensaje);
                            this.componentDidMount();
                        }else{
                            toast.error('Error al borrar venta extra.');
                            console.log(data);
                        }
                    }
                }else{
                    toast.dismiss();
                    toast.error('Solo puede borrar ventas extra del día en curso.');
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value 
        });
    }

    agregarVentaExtra = async () => {
        this.setState({
            cargando: true
        })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                if (this.state.concepto !== '' && Number(this.state.monto) !== 0) {
                    if (window.confirm('¿Está seguro de finalizar la venta? Se registrará independientemente de si imprime el ticket o no.')) {
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ 
                                operacion: 'insert', 
                                usuario: this.context.rootState.theUser.idUsuario,
                                sucursal: this.context.rootState.theUser.us_idSucursal, 
                                concepto: this.state.concepto, 
                                total: this.state.monto, 
                                metodoPago: this.state.metodoPago })
                        };
                        const response = await fetch('https://pos.panaderialazcano.com/php/DB/ventasextra.php', requestOptions);
                        const data = await response.json();
                        console.log(data);
                        if (data.error === 0) {
                            this.setState({
                                fechaTicket: data.fechaTicket,
                                idVentaTicket: data.idVentaTicket
                            })
                            const contenidoDiv = document.getElementById('ticket').innerHTML;
                            const ventanaImpresion = window.open('', '', 'height=700,width=1000');
                            ventanaImpresion.document.write('<html><head><title>Imprimir</title></head><body>');
                            ventanaImpresion.document.write(contenidoDiv);
                            ventanaImpresion.document.write('</body></html>');
                            ventanaImpresion.document.close();
                            ventanaImpresion.onafterprint = function() {
                                ventanaImpresion.close();
                            };
                            ventanaImpresion.print();
                            toast.success(data.mensaje);
                            this.setState({
                                concepto: '',
                                monto: '',
                                fechaTicket: '',
                                idVentaTicket: '',
                                metodoPago: 'transferencia',
                                cargando: false
                            });
                            this.componentDidMount();
                        }else{
                            toast.error('Error al registrar venta extra.');
                            this.setState({
                                cargando: false
                            })
                            console.log(data);
                        }
                    }
                }else{
                    toast.error('Para eso, llene los campos solicitados.');
                    this.setState({
                        cargando: false
                    })
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    reimprimirTicket = async(idTicket, fecha, concepto, monto) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                let fechaHoy = new Date().toLocaleDateString();
                let fechaVenta = new Date(fecha).toLocaleDateString();
                if (fechaHoy === fechaVenta) {
                    if (window.confirm("Reimprimir ticket sólo debe ser usado en caso de error de impresión en el primer intento.")) {
                        await this.setState({
                            idVentaTicket: idTicket,
                            fechaTicket: fecha,
                            concepto: concepto,
                            monto: monto
                        })
                        const contenidoDiv = document.getElementById('ticket').innerHTML;
                        const ventanaImpresion = window.open('', '', 'height=700,width=1000');
                        ventanaImpresion.document.write('<html><head><title>Imprimir</title></head><body>');
                        ventanaImpresion.document.write(contenidoDiv);
                        ventanaImpresion.document.write('</body></html>');
                        ventanaImpresion.document.close();
                        ventanaImpresion.onafterprint = function() {
                            ventanaImpresion.close();
                        };
                        ventanaImpresion.print();
                        this.setState({
                            idVentaTicket: '',
                            fechaTicket: '',
                            concepto: '',
                            monto: ''
                        })
                    }
                }else{
                    toast.dismiss();
                    toast.error('Solo puede editar ventas del día en curso.');
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    render() { 
        return ( 
            <>
                <Navbar/>
                <div className="container-fluid ventas">
                    <div id='ticket' style={{ height: 'fit-content', backgroundColor: 'white', width: '58mm', overflowX: 'hidden', position: 'absolute', padding: '0', left: '-1000px'}}>
                        <p style={{ fontSize: '16px', textAlign: 'center', margin: '0' }}>Panadería Lazcano</p>
                        <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>{this.context.rootState.theUser.nombreSucursal}</p>
                        <p style={{ margin: '0', fontSize: '12px' }}>Folio: VE-{this.state.idVentaTicket}</p>
                        <p style={{ margin: '0', fontSize: '12px' }}>Fecha: {this.state.fechaTicket}</p>
                        <div style={{ display: 'grid', grid: '1fr / 1fr 1fr 1fr', margin: '0', fontSize: '12px' }}>
                            <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Cantidad</p>
                            <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Unitario</p>
                            <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Importe</p>
                        </div>
                        <div style={{ display: 'grid', grid: '1fr 1fr / 1fr 1fr 1fr' }}>
                            <p style={{ gridColumn: 'span 3', whiteSpace: 'nowrap', margin: '0', fontSize: '12px' }}>{this.state.concepto}</p>
                            <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>1</p>
                            <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{Number(this.state.monto).toFixed(2)}</p>
                            <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{Number(this.state.monto).toFixed(2)}</p>
                        </div>
                        <p style={{ margin: '0', fontSize: '11px' }}>---------------------------------------------</p>
                        <p style={{ textAlign: 'end', margin: '0', fontSize: '12px' }}>Total: ${this.numberWithCommas(Number(this.state.monto).toFixed(2))}</p>
                        <p style={{ textAlign: 'center', margin: '0', marginTop: '30px', fontSize: '12px' }}>¡Gracias por su compra!</p>
                        <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>Le atendió {this.context.rootState.theUser.nombre}</p>
                    </div>
                    <div className="row">
                        <div className="col-7 px-4">
                            <div className="row pt-4">
                                <div className='containerVentas'>
                                    <div className="row py-2" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-2 text-center">
                                            Fecha
                                        </div>
                                        <div className="col-2 text-center">
                                            Hora
                                        </div>
                                        <div className="col-2 text-center">
                                            Concepto
                                        </div>
                                        <div className="col-2 text-center">
                                            M. Pago
                                        </div>
                                        <div className="col-2 text-center">
                                            Total
                                        </div>
                                        <div className="col-2 text-center">
                                            -
                                        </div>
                                    </div>
                                    {
                                        this.state.ventasExtra[0] ?
                                            this.state.ventasExtra.map((e, index) =>
                                                <div className="row elementoVentas py-3" key={index}>
                                                    <div className="col-2 d-flex justify-content-center align-items-center">
                                                        <p className='m-0'>{new Date(e.fecha).toLocaleDateString()}</p>
                                                    </div>
                                                    <div className="col-2 d-flex justify-content-center align-items-center">
                                                        {new Date(e.fecha).toLocaleTimeString()}
                                                    </div>
                                                    <div className="col-2 d-flex align-items-center">
                                                        {e.concepto}
                                                    </div>
                                                    <div className="col-2 d-flex align-items-center justify-content-center" style={{ textTransform: 'capitalize' }}>
                                                        {e.metodoPago}
                                                    </div>
                                                    <div className="col-2 d-flex justify-content-end align-items-center">
                                                        ${this.numberWithCommas(e.total)}
                                                    </div>
                                                    <div className="col-2 d-flex justify-content-center align-items-center">
                                                        <button className='btn btn-success me-1' onClick={() => this.reimprimirTicket(e.idVentaExtra, e.fecha, e.concepto, e.total)}><i className="bi bi-printer-fill"></i></button>
                                                        <button className='btn btn-success ms-1 btnFinalizarVenta' onClick={() => this.eliminarVentaExtra(e.idVentaExtra, e.fecha, e.concepto, this.numberWithCommas(e.total))}><i className="bi bi-trash2-fill"></i></button>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <div className="row mt-3">
                                                <div className="col-12 text-center">
                                                    No hay ventas extra registradas en esta fecha.
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-5 px-4 pt-4">
                            <div className="row">
                                <div className='containerDetalleVenta' style={{ height: 'fit-content' }}>
                                    <div className="row py-3" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-12 text-center">
                                            <h5 className='m-0'>Registrar Venta Extra</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 pt-2">
                                            <label htmlFor="concepto" className='ms-1'>Concepto</label>
                                                <input type="text" id='concepto' className='form-control' name='concepto' value={this.state.concepto} onChange={this.onChangeValue}/>
                                            <label htmlFor="monto" className='ms-1 mt-3'>Monto</label>
                                                <NumericFormat value={this.state.monto} className="form-control text-end" defaultValue='' placeholder='$0.0' onValueChange={(values) => {if(this.state.idVentaTicket === ''){this.setState({ monto: Number(values.value)})}}} allowNegative={false} decimalScale={2} thousandSeparator=',' prefix={'$'}/>
                                            <label htmlFor="metodoPago" className='ms-1 mt-3'>Método de Pago</label>
                                                <select name="metodoPago" id="metodoPago" className='form-control' value={this.state.metodoPago} onChange={this.onChangeValue}>
                                                    <option value="efectivo">Efectivo</option>
                                                    <option value="transferencia">Transferencia</option>
                                                </select>
                                            <button className='my-3 mx-auto d-flex btn btnFinalizarVenta px-4' onClick={this.agregarVentaExtra}>Agregar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toaster containerClassName='text-center'/>
                <Modal show={this.state.cargando} centered size='sm' className='modalCargando'>
                    <Modal.Body className='ms-auto me-auto'>
                        <MoonLoader color={'#ffffff'} loading={true} size={100} />
                    </Modal.Body>
                </Modal>   
            </>
         );
    }
}
 
export default VentasExtra;