import React from 'react';
import { MyContext } from '../../contexts/MyContext';
import Navbar from '../Navbar';
import '../../css/ventas.css';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import EditarPedido from './EditarPedido';

class Pedidos extends React.Component {

    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.state = {
            pedidos: [],
            pedidosFiltrado: [],
            fechaFiltro: '',
            pedidoSeleccionado: [],
            showModalEntregar: false,
            showModalEliminar: false,
            showModalEditar: false,
            comentario: '',
            pedidoEditar: [],
            vista: 'todos',
            metodoPago: '',
            bolsas: [],
            fechaTicket: '',
            idPedidoTicket: ''            
        }
    }

    componentDidMount = async () => {
        document.title = 'Pedidos';
        window.addEventListener('resize', this.getHeightWindow);
        window.addEventListener('beforeunload', this.alertUser);
        this.getHeightWindow();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select', sucursal: this.context.rootState.theUser.us_idSucursal })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/pedidos.php', requestOptions);
        const data = await response.json();
        this.setState({
            pedidos: data,
            pedidosFiltrado: data
        })
        this.filtrarTodos();
        this.selectBolsas();
    }

    selectBolsas = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectBolsas', sucursal: this.context.rootState.theUser.us_idSucursal })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/productos.php', requestOptions);
        const data = await response.json();
        let bolsas = [];
        data.productos.forEach(element => {
            bolsas.push({idProducto: element.idProducto, precio: element.precio, inventario: element.inventario, nombre: element.nombre, cantidad: 0});
        });
        this.setState({
            bolsas: bolsas
        })
    }
    
    alertUser = (e) => {
        if (this.state.showModalEditar || this.state.showModalEntregar) {
            e.preventDefault();
            e.returnValue = '';  
        }
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    getHeightWindow = () => {
        var height = window.innerHeight;
        if (document.getElementsByClassName("containerVentas")[0]) {
            document.getElementsByClassName("containerVentas")[0].style.height = (height - 180) + 'px';
            document.getElementsByClassName("containerDetalleVenta")[0].style.height = (height - 180) + 'px';
        }
    }

    filtrarEntregaHoy = () => {
        let fechaHoy = new Date();
        let dataFiltrada = this.state.pedidos.filter((e) => (new Date(e.fechaEntregaProvista + 'T00:00:00').toLocaleDateString() === fechaHoy.toLocaleDateString()) && e.estatus !== 'Entregado' );
        this.setState({
            pedidosFiltrado: dataFiltrada,
            fechaFiltro: '',
            vista: 'entregaHoy'
        })
    }
    
    filtrarEntregadosHoy = () => {
        let fechaHoy = new Date();
        let dataFiltrada = this.state.pedidos.filter((e) => (new Date(e.fechaEntrega).toLocaleDateString() === fechaHoy.toLocaleDateString()) && e.estatus === 'Entregado' );
        this.setState({
            pedidosFiltrado: dataFiltrada,
            fechaFiltro: '',
            vista: 'entregadoHoy'
        })
    }

    filtrarTodos = () => {
        this.setState({
            pedidosFiltrado: this.state.pedidos,
            fechaFiltro: '',
            vista: 'todos'
        })
    }

    selectPedido = async (e) => {
        let idPedido = e.target.id;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectWhere', idPedido: idPedido })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/pedidos.php', requestOptions);
        const data = await response.json();
        this.setState({
            pedidoSeleccionado: data[0]
        });
    }

    showModalEditar = () => {
        if (this.state.pedidoSeleccionado.idPedido) {
            if (window.confirm("Recurde que para editar un pedido, debe solicitar el ticket anterior al cliente.")) {                    
                this.setState({
                    showModalEditar: true
                });
            }
        }else{
            toast.dismiss();
            toast.error('Para eso, seleccione un pedido.');
        }
    }

    hideModalEditarUpdate = () => {
        this.setState({
            showModalEditar: false,
            pedidoSeleccionado: []
        });
        this.componentDidMount();
    }

    hideModalEditar = () => {
        this.setState({
            showModalEditar: false
        })
    }

    hideModalEliminar = () => {
        this.setState({
            showModalEliminar: false
        });
    }

    showModalEliminar = () => {
        if (this.state.pedidoSeleccionado.idPedido) {
            let fechaHoy = new Date().toLocaleDateString();
            let fechaPedido = new Date(this.state.pedidoSeleccionado.fecha).toLocaleDateString();
            if (fechaHoy === fechaPedido) {
                this.setState({
                    showModalEliminar: true
                });
            }else{
                toast.dismiss();
                toast.error('Solo puede borrar pedidos del día en curso.');
            }
        }else{
            toast.dismiss();
            toast.error('Para eso, seleccione un pedido.');
        }
    }

    showModalEntregar = () => {
        this.setState({
            showModalEntregar: true
        })
    }

    hideModalEntregar = () => {
        this.setState({
            showModalEntregar: false,
            metodoPago: ''
        })
    }

    eliminarPedido = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                if (this.state.comentario !== '') {
                    let idPedido = this.state.pedidoSeleccionado.idPedido;
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 
                            operacion: 'delete', 
                            usuario: this.context.rootState.theUser.idUsuario,
                            idPedido: idPedido, 
                            comentario: this.state.comentario,
                            sucursal: this.context.rootState.theUser.us_idSucursal 
                        })
                    };
                    const response = await fetch('https://pos.panaderialazcano.com/php/DB/pedidos.php', requestOptions);
                    const data = await response.json();
                    toast.success(data.mensaje);
                    this.hideModalEliminar();
                    this.componentDidMount();
                    this.setState({
                        pedidoSeleccionado: []
                    })
                }else{
                    toast.error('Debe escribir un motivo para la eliminación.');
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value 
        });
    }

    reimprimirTicket = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                if (window.confirm("Reimprimir ticket sólo debe ser usado en caso de error de impresión.")) {
                    const contenidoDiv = document.getElementById(this.state.pedidoSeleccionado.estatus === 'Entregado' ? 'ticketEntregado' : 'ticket').innerHTML;
                    const ventanaImpresion = window.open('', '', 'height=700,width=1000');
                    ventanaImpresion.document.write('<html><head><title>Imprimir</title></head><body>');
                    ventanaImpresion.document.write(contenidoDiv);
                    ventanaImpresion.document.write('</body></html>');
                    ventanaImpresion.document.close();
                    ventanaImpresion.onafterprint = function() {
                        ventanaImpresion.close();
                    };
                    ventanaImpresion.print();
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    anadirBolsa = (index) => {
        if (this.state.bolsas[index].inventario > this.state.bolsas[index].cantidad) {
            let bolsas = this.state.bolsas;
            bolsas[index].cantidad += 1;
            this.setState({
                bolsas: bolsas
            })
        }else{
            toast.error('Inventario insuficiente.');
        }
    }

    quitarBolsa = (index) => {
        if (this.state.bolsas[index].cantidad > 0) {            
            let bolsas = this.state.bolsas;
            bolsas[index].cantidad -= 1;
            this.setState({
                bolsas: bolsas
            })
        }
    }

    hideMetodoPago = () => {
        let hide = true;
        if (this.state.pedidoSeleccionado.estatus === 'Pagado') {
            hide = true;
        }else{
            hide = false;
        }
        this.state.bolsas.forEach(element => {
            if (element.cantidad !== 0) {
                hide = false;
            }
        });
        return hide
    }

    entregarPedido = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'corroborarAcceso', idUsuario: this.context.rootState.theUser.idUsuario })
        };
        const response = await fetch('https://pos.panaderialazcano.com/php/DB/usuarios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (data.allow === false) {
                this.context.logoutUser();
            }else{
                if (this.hideMetodoPago() === false) {
                    if (this.state.metodoPago === '') {
                        toast.error("Seleccione un método de pago");
                    }else{
                        if (window.confirm('¿Entregar pedido? Esta acción no se puede revertir')) {
                            let bolsas = this.state.bolsas.filter(e => e.cantidad !== 0);
                            const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ 
                                    operacion: 'entregarPedido', 
                                    usuario: this.context.rootState.theUser.idUsuario,
                                    sucursal: this.context.rootState.theUser.us_idSucursal,
                                    idPedido: this.state.pedidoSeleccionado.idPedido,
                                    bolsas: bolsas,
                                    metodoPago: this.state.metodoPago,
                                    total: this.getTotal()
                                })
                            };
                            const response = await fetch('https://pos.panaderialazcano.com/php/DB/pedidos.php', requestOptions);
                            const data = await response.json();
                            if (data.error === 0) {
                                this.setState({
                                    fechaTicket: data.fechaTicket,
                                    idPedidoTicket: data.idPedidoTicket
                                })
                                const contenidoDiv = document.getElementById('ticketEntrega').innerHTML;
                                const ventanaImpresion = window.open('', '', 'height=700,width=1000');
                                ventanaImpresion.document.write('<html><head><title>Imprimir</title></head><body>');
                                ventanaImpresion.document.write(contenidoDiv);
                                ventanaImpresion.document.write('</body></html>');
                                ventanaImpresion.document.close();
                                ventanaImpresion.onafterprint = function() {
                                    ventanaImpresion.close();
                                };
                                ventanaImpresion.print();                
                                toast.success('Pedido Entregado.');
                                this.setState({
                                    fechaTicket: '',
                                    idPedidoTicket: '',
                                    pedidoSeleccionado: []
                                })
                                this.hideModalEntregar();
                                this.componentDidMount();                    
                            }else{
                                if (data.codigoError === '22003') {
                                    toast.error('Piezas insuficientes, recargue inventario.')
                                }else{
                                    toast.error('Hubo un error al realizar la petición.');
                                    console.log(data.mensaje);
                                }                    
                            }
                        }
                    }
                }else{
                    if (window.confirm('¿Entregar pedido? Esta acción no se puede revertir')) {
                        let bolsas = this.state.bolsas.filter(e => e.cantidad !== 0);
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ 
                                operacion: 'entregarPedido', 
                                usuario: this.context.rootState.theUser.idUsuario,
                                sucursal: this.context.rootState.theUser.us_idSucursal,
                                idPedido: this.state.pedidoSeleccionado.idPedido,
                                bolsas: bolsas,
                                total: this.getTotal()
                            })
                        };
                        const response = await fetch('https://pos.panaderialazcano.com/php/DB/pedidos.php', requestOptions);
                        const data = await response.json();
                        if (data.error === 0) {
                            this.setState({
                                fechaTicket: data.fechaTicket,
                                idPedidoTicket: data.idPedidoTicket
                            })
                            const contenidoDiv = document.getElementById('ticketEntrega').innerHTML;
                            const ventanaImpresion = window.open('', '', 'height=700,width=1000');
                            ventanaImpresion.document.write('<html><head><title>Imprimir</title></head><body>');
                            ventanaImpresion.document.write(contenidoDiv);
                            ventanaImpresion.document.write('</body></html>');
                            ventanaImpresion.document.close();
                            ventanaImpresion.onafterprint = function() {
                                ventanaImpresion.close();
                            };
                            ventanaImpresion.print();                
                            toast.success('Pedido Entregado.');
                            this.setState({
                                fechaTicket: '',
                                idPedidoTicket: '',
                                pedidoSeleccionado: []
                            })
                            this.hideModalEntregar();
                            this.componentDidMount();                    
                        }else{
                            if (data.codigoError === '22003') {
                                toast.error('Piezas insuficientes, recargue inventario.')
                            }else{
                                toast.error('Hubo un error al realizar la petición.');
                                console.log(data.mensaje);
                            }                    
                        }
                    }
                }
            }
        }else{
            this.context.logoutUser();
        }
    }

    getTotal = () => {
        let total = 0;
        this.state.bolsas.forEach(element => {
            total += Number(element.precio) * Number(element.cantidad); 
        });
        total += Number(this.state.pedidoSeleccionado.total);
        return total
    }

    getRestante = () => {
        let restante = 0;
        if (this.state.pedidoSeleccionado.estatus === 'Pagado') {
            this.state.bolsas.forEach(element => {
                restante += Number(element.precio) * Number(element.cantidad); 
            });
        }else{
            restante = Number(this.state.pedidoSeleccionado.total) - Number(this.state.pedidoSeleccionado.anticipo);
            let totalBolsas = 0;
            this.state.bolsas.forEach(element => {
                totalBolsas += Number(element.precio) * Number(element.cantidad); 
            });
            restante += totalBolsas;
        }
        return restante
    }
    
    render() { 
        return ( 
            <>
                <Navbar/>
                <div className="container-fluid ventas">
                    <div className="row">
                        <div className="col-5 px-4 pt-4">
                            <div className="row">
                                <div className="col-4">
                                    <button className={'btn btn-lg btnInicio ' + (this.state.vista === 'todos' ? 'btn-success' : 'btn-outline-success')} onClick={this.filtrarTodos}>Todos</button>
                                </div>
                                <div className="col-4">
                                    <button className={'btn btn-lg btnInicio ' + (this.state.vista === 'entregaHoy' ? 'btn-success' : 'btn-outline-success')} onClick={this.filtrarEntregaHoy}>Entrega Hoy</button>
                                </div>
                                <div className="col-4">
                                    <button className={'btn btn-lg btnInicio ' + (this.state.vista === 'entregadoHoy' ? 'btn-success' : 'btn-outline-success')} onClick={this.filtrarEntregadosHoy}>Entregado Hoy</button>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className='containerVentas'>
                                    <div className="row py-2" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-3 text-center">
                                            Nombre
                                        </div>
                                        <div className="col-3 text-center">
                                            Fecha Entrega
                                        </div>
                                        <div className="col-3 text-center">
                                            Fecha Pedido
                                        </div>
                                        <div className="col-3 text-center">
                                            Estatus
                                        </div>
                                    </div>
                                    {
                                        this.state.pedidosFiltrado[0] ?
                                            this.state.pedidosFiltrado.map((e, index) =>
                                                <div className="row elementoVentas py-3" key={index} id={e.idPedido} onClick={this.selectPedido} style={{ cursor: 'pointer' }}>
                                                    <div className="col-3 text-start" id={e.idPedido}>
                                                        {e.nombre}
                                                    </div>
                                                    <div className="col-3 text-center" id={e.idPedido}>
                                                        {new Date(e.fechaEntregaProvista + 'T00:00:00').toLocaleDateString()}
                                                    </div>
                                                    <div className="col-3 text-center" id={e.idPedido}>
                                                        {new Date(e.fecha).toLocaleDateString()}
                                                    </div>
                                                    <div className="col-3 text-center" id={e.idPedido}>
                                                        {e.estatus}
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <div className="row mt-3">
                                                <div className="col-12 text-center">
                                                    No hay pedidos que coincidan con lo solicitado.
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-7 px-4 pt-4">
                            <div className="row">
                                <div className='containerDetalleVenta' style={{ overflowY: 'auto' }}>
                                    <div className="row py-3" style={{ borderBottom: '5px solid #afc37f' }}>
                                        <div className="col-12 text-center">
                                            <h5 className='m-0'>Detalles del Pedido</h5>
                                        </div>
                                    </div>
                                    {
                                        this.state.pedidoSeleccionado.idPedido &&
                                            <>
                                                <div className="row" style={{ borderBottom: '5px solid #afc37f' }}>
                                                    <div className="col-4 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Fecha: {new Date(this.state.pedidoSeleccionado.fecha).toLocaleDateString()}</h6>
                                                    </div>
                                                    <div className="col-4 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Hora: {new Date(this.state.pedidoSeleccionado.fecha).toLocaleTimeString()}</h6>
                                                    </div>
                                                    <div className="col-4 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Fecha Entrega: {new Date(this.state.pedidoSeleccionado.fechaEntregaProvista + 'T00:00:00').toLocaleDateString()}</h6>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ borderBottom: '5px solid #afc37f' }}>
                                                    <div className="col-3 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Estatus: {this.state.pedidoSeleccionado.estatus}</h6>
                                                    </div>
                                                    <div className="col-3 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Anticipo: {this.state.pedidoSeleccionado.anticipo && ('$' + this.numberWithCommas(this.state.pedidoSeleccionado.anticipo))}</h6>
                                                    </div>
                                                    <div className="col-3 text-center py-2" style={{ borderRight: '5px solid #afc37f' }}>
                                                        <h6 className='m-0'>Restante: {this.state.pedidoSeleccionado.anticipo && ('$' + this.numberWithCommas(Number(this.state.pedidoSeleccionado.total) - Number(this.state.pedidoSeleccionado.anticipo)))}</h6>
                                                    </div>
                                                    <div className="col-3 text-center py-2">
                                                        <h6 className='m-0'>Total: ${this.numberWithCommas(this.state.pedidoSeleccionado.total)}</h6>
                                                    </div>
                                                </div>
                                                {this.state.pedidoSeleccionado.productos.map((e, index) => 
                                                    <div className="row py-3" key={index} style={{ borderBottom: '3px solid #afc37f3a' }}>
                                                        <div className="col-4 text-center">
                                                            {e.nombre}
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            {e.cantidad} Pzs.
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            ${this.numberWithCommas(e.precioUnitario)}
                                                        </div>
                                                    </div>
                                                )}
                                                <div id='ticket' style={{ height: 'fit-content', backgroundColor: 'white', width: '58mm', overflowX: 'hidden', position: 'absolute', padding: '0', left: '-1000px'}}>
                                                    <p style={{ fontSize: '16px', textAlign: 'center', margin: '0' }}>Panadería Lazcano</p>
                                                    <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>{this.context.rootState.theUser.nombreSucursal}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Folio: {this.state.pedidoSeleccionado.idPedido}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Fecha: {this.state.pedidoSeleccionado.fecha}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Entrega Programada: {this.state.pedidoSeleccionado.fechaEntregaProvista}</p>
                                                    <div style={{ display: 'grid', grid: '1fr / 1fr 1fr 1fr', margin: '0', fontSize: '12px' }}>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Cantidad</p>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Unitario</p>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Importe</p>
                                                    </div>
                                                    {
                                                        this.state.pedidoSeleccionado.productos.map((e, index) =>
                                                            <div key={index} style={{ display: 'grid', grid: '1fr 1fr / 1fr 1fr 1fr' }}>
                                                                <p style={{ gridColumn: 'span 3', whiteSpace: 'nowrap', margin: '0', fontSize: '12px' }}>{e.nombre}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>{e.cantidad}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{Number(e.precioUnitario).toFixed(2)}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{(e.precioUnitario * e.cantidad).toFixed(2)}</p>
                                                            </div>
                                                        )
                                                    }
                                                    <p style={{ margin: '0', fontSize: '11px' }}>---------------------------------------------</p>
                                                    <p style={{ textAlign: 'end', margin: '0', fontSize: '12px' }}>Total: ${this.numberWithCommas(this.state.pedidoSeleccionado.total)}</p>
                                                    <p style={{ textAlign: 'start', margin: '0', marginTop: '10px', fontSize: '12px' }}>Pago: {this.state.pedidoSeleccionado.estatus} {this.state.pedidoSeleccionado.estatus === 'Anticipo' && '($' + this.numberWithCommas(this.state.pedidoSeleccionado.anticipo) + ')' }</p>
                                                    <p style={{ textAlign: 'center', margin: '0', marginTop: '30px', fontSize: '12px' }}>¡Gracias por su pedido!</p>
                                                    <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>Le atendió {this.context.rootState.theUser.nombre}</p>
                                                </div>
                                                <div id='ticketEntrega' style={{ height: 'fit-content', backgroundColor: 'white', width: '58mm', overflowX: 'hidden', position: 'absolute', padding: '0', left: '-1000px'}}>
                                                    <p style={{ fontSize: '16px', textAlign: 'center', margin: '0' }}>Panadería Lazcano</p>
                                                    <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>{this.context.rootState.theUser.nombreSucursal}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Folio: {this.state.pedidoSeleccionado.idPedido}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Fecha: {this.state.fechaTicket}</p>
                                                    <div style={{ display: 'grid', grid: '1fr / 1fr 1fr 1fr', margin: '0', fontSize: '12px' }}>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Cantidad</p>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Unitario</p>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Importe</p>
                                                    </div>
                                                    {
                                                        this.state.pedidoSeleccionado.productos.map((e, index) =>
                                                            <div key={index} style={{ display: 'grid', grid: '1fr 1fr / 1fr 1fr 1fr' }}>
                                                                <p style={{ gridColumn: 'span 3', whiteSpace: 'nowrap', margin: '0', fontSize: '12px' }}>{e.nombre}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>{e.cantidad}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{Number(e.precioUnitario).toFixed(2)}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{(e.precioUnitario * e.cantidad).toFixed(2)}</p>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        this.state.bolsas.map((e, index) =>
                                                            <div key={index} style={{ display: 'grid', grid: '1fr 1fr / 1fr 1fr 1fr' }}>
                                                                <p style={{ gridColumn: 'span 3', whiteSpace: 'nowrap', margin: '0', fontSize: '12px' }}>{e.nombre}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>{e.cantidad}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{Number(e.precio).toFixed(2)}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{(e.precio * e.cantidad).toFixed(2)}</p>
                                                            </div>
                                                        )
                                                    }
                                                    <p style={{ margin: '0', fontSize: '11px' }}>---------------------------------------------</p>
                                                    <p style={{ textAlign: 'end', margin: '0', fontSize: '12px' }}>Total: ${this.numberWithCommas(this.getTotal())}</p>
                                                    <p style={{ textAlign: 'center', margin: '0', marginTop: '30px', fontSize: '12px' }}>¡Gracias por su pedido!</p>
                                                    <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>Le atendió {this.context.rootState.theUser.nombre}</p>
                                                </div>
                                                <div id='ticketEntregado' style={{ height: 'fit-content', backgroundColor: 'white', width: '58mm', overflowX: 'hidden', position: 'absolute', padding: '0', left: '-1000px'}}>
                                                    <p style={{ fontSize: '16px', textAlign: 'center', margin: '0' }}>Panadería Lazcano</p>
                                                    <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>{this.context.rootState.theUser.nombreSucursal}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Folio: {this.state.pedidoSeleccionado.idPedido}</p>
                                                    <p style={{ margin: '0', fontSize: '12px' }}>Fecha: {this.state.pedidoSeleccionado.fechaEntrega}</p>
                                                    <div style={{ display: 'grid', grid: '1fr / 1fr 1fr 1fr', margin: '0', fontSize: '12px' }}>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Cantidad</p>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Unitario</p>
                                                        <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>Importe</p>
                                                    </div>
                                                    {
                                                        this.state.pedidoSeleccionado.productos.map((e, index) =>
                                                            <div key={index} style={{ display: 'grid', grid: '1fr 1fr / 1fr 1fr 1fr' }}>
                                                                <p style={{ gridColumn: 'span 3', whiteSpace: 'nowrap', margin: '0', fontSize: '12px' }}>{e.nombre}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'center', margin: '0', fontSize: '12px' }}>{e.cantidad}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{Number(e.precioUnitario).toFixed(2)}</p>
                                                                <p style={{ display: 'flex', justifyContent: 'flex-end', margin: '0', fontSize: '12px' }}>{(e.precioUnitario * e.cantidad).toFixed(2)}</p>
                                                            </div>
                                                        )
                                                    }
                                                    <p style={{ margin: '0', fontSize: '11px' }}>---------------------------------------------</p>
                                                    <p style={{ textAlign: 'end', margin: '0', fontSize: '12px' }}>Total: ${this.numberWithCommas(this.getTotal())}</p>
                                                    <p style={{ textAlign: 'center', margin: '0', marginTop: '30px', fontSize: '12px' }}>¡Gracias por su pedido!</p>
                                                    <p style={{ textAlign: 'center', margin: '0', fontSize: '12px' }}>Le atendió {this.context.rootState.theUser.nombre}</p>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="row pt-4 justify-content-center">
                                <button className='btn btn-success me-2 btn-lg w-auto' onClick={this.showModalEntregar} hidden={!this.state.pedidoSeleccionado.idPedido || this.state.pedidoSeleccionado.estatus === 'Entregado'}>Entregar Pedido</button>
                                <button className='btn btn-secondary me-2 btn-lg w-auto' onClick={this.showModalEditar} hidden={!this.state.pedidoSeleccionado.idPedido || this.state.pedidoSeleccionado.estatus === 'Entregado'}>Editar Pedido</button>
                                <button className='btn ms-2 btn-lg btnFinalizarVenta w-auto' onClick={this.showModalEliminar} hidden={!this.state.pedidoSeleccionado.idPedido || this.state.pedidoSeleccionado.estatus === 'Entregado'}>Cancelar Pedido</button>
                                <button className='btn btn-primary ms-2 btn-lg w-auto' onClick={this.reimprimirTicket} hidden={!this.state.pedidoSeleccionado.idPedido}>Reimprimir Ticket</button>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.showModalEntregar} onHide={this.hideModalEntregar} centered>
                        <Modal.Header>
                                <h4>Entregar Pedido</h4>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                this.state.pedidoSeleccionado.idPedido &&
                                <div className='row'>
                                    <div className="col-12">
                                        <p className='m-0' style={{ fontSize: '20px' }}>Nombre: {this.state.pedidoSeleccionado.nombre}</p>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <p className='m-0' style={{ fontSize: '20px' }}>Pago: {this.state.pedidoSeleccionado.estatus}</p> 
                                    </div>
                                    {
                                        this.state.bolsas.map((e, index) =>
                                            <div className="col-6 mt-3" key={index}>
                                                <label htmlFor="index" style={{ fontSize: '20px' }}>{e.nombre}</label>
                                                <div className="row m-0">
                                                    <div className="col-8 border border-end-0" style={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}>
                                                        <p className='w-100 h-100 m-0 d-flex justify-content-center align-items-center' style={{ fontSize: '30px' }}>{e.cantidad}</p>
                                                    </div>
                                                    <div className="col-4 border" style={{ borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}>
                                                        <div className="row">
                                                            <div className="col-12 border-bottom p-0">
                                                                <button className='btnAgregar w-100 h-100 border-0' style={{ backgroundSize: '50%' }} onClick={ () => this.anadirBolsa(index) }/>
                                                            </div>
                                                            <div className="col-12 p-0">
                                                                <button className='btnQuitar w-100 h-100 border-0' style={{ backgroundSize: '50%' }} onClick={ () => this.quitarBolsa(index) }/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className="col-12 mt-3" hidden={this.hideMetodoPago()}>
                                        <label style={{ fontSize: '20px' }}>Método de pago</label>
                                    </div>
                                    <div className="col-6 mt-1" hidden={this.hideMetodoPago()}>
                                        <button type='button' className={'btn btn-lg w-100 ' + (this.state.metodoPago === 'efectivo' ? 'btn-success' : 'btn-outline-secondary')} onClick={() => this.setState({ metodoPago: 'efectivo' })}>Efectivo</button>
                                    </div>
                                    <div className="col-6 mt-1" hidden={this.hideMetodoPago()}>
                                        <button type='button' className={'btn btn-lg w-100 ' + (this.state.metodoPago === 'transferencia' ? 'btn-success' : 'btn-outline-secondary')} onClick={() => this.setState({ metodoPago: 'transferencia' })}>Transferencia</button>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <table className='table table-sm table-bordered bg-dark mb-0'>
                                            <thead>
                                                <tr className='text-center'>
                                                    <th style={{ width: '33%', backgroundColor: '#F2F2F2', fontWeight: '500' }}>Total</th>
                                                    <th style={{ width: '33%', fontWeight: '500' }}>Anticipo</th>
                                                    <th style={{ backgroundColor: '#F2F2F2', fontWeight: '500' }}>Restante</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='text-end'>
                                                    <td className='px-2' style={{ backgroundColor: '#F2F2F2' }}>$ {this.numberWithCommas(this.getTotal())}</td>
                                                    <td className='px-2'>$ {this.numberWithCommas(this.state.pedidoSeleccionado.anticipo)}</td>
                                                    <td className='px-2' style={{ backgroundColor: '#F2F2F2', fontWeight: '500' }}>$ {this.numberWithCommas(this.getRestante())}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-secondary' onClick={this.hideModalEntregar}>Cancelar</button>
                            <button className='btn btn-success' onClick={this.entregarPedido}>Entregar Pedido</button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.showModalEditar} onHide={this.hideModalEditar} fullscreen style={{ backgroundColor: '#e1e4c8' }}>
                        <Modal.Header className='d-flex justify-content-center' style={{ backgroundColor: '#947450', color: 'white' }} closeButton>
                            <h4>Editar Pedido</h4>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: '#e1e4c8' }}>
                            <EditarPedido pedido={this.state.pedidoSeleccionado} cerrarModal={this.hideModalEditarUpdate}/>
                        </Modal.Body>
                    </Modal>
                    <Modal show={this.state.showModalEliminar} onHide={this.hideModalEliminar} centered>
                        <Modal.Header>
                            <h4>Cancelar Pedido</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <label>Motivo de Cancelación:</label>
                            <textarea className='form-control' name="comentario" cols="30" rows="5" value={this.state.comentario} onChange={this.onChangeValue}></textarea>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-secondary' onClick={this.hideModalEliminar}>Atras</button>
                            <button className='btn btn-danger' onClick={this.eliminarPedido}>Cancelar Pedido</button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <Toaster containerClassName='text-center'/>
            </>
         );
    }
}
 
export default Pedidos;