import React, { Component } from 'react';
import imgPrueba from '../img/panprueba.png';
import '../css/ticket.css'

class Pruebas extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    
    probar = () => {
        const contenidoDiv = document.getElementById('ticket').innerHTML;
        console.log(contenidoDiv);
        const ventanaImpresion = window.open('', '', 'height=500,width=500');
        ventanaImpresion.document.write('<html><head><title>Imprimir</title></head><body>');
        ventanaImpresion.document.write(contenidoDiv);
        ventanaImpresion.document.write('</body></html>');
        ventanaImpresion.document.close();
        ventanaImpresion.onafterprint = function() {
            ventanaImpresion.close();
        };
        ventanaImpresion.print();
    }

    render() { 
        return ( 
            <>
                <div className='ticket' id='ticket' style={{ height: 'fit-content', backgroundColor: 'white', width: '58mm' }}>
                    <p style={{ textAlign: 'center' }}>Pan Lazcano</p>
                    <p>Elemento</p>
                    <p>Elemento</p>
                    <p className='text-end'>Total: $74.00</p>
                    <p className='text-center' style={{ color: 'red' }}>Hora</p>
                </div>
                <button onClick={() => this.probar()}>Probar</button>
                <img src={imgPrueba} alt="" width={'100px'}/>
            </>
         );
    }
}
 
export default Pruebas;